import { TEXT_AREA_LIMIT_LONG } from '@/constants';
import {
  LocationEnvironmentTypes,
  ValidLocationEnvironmentTypes,
} from '@/types';
import { Type } from 'class-transformer';
import {
  IsAlpha,
  IsEnum,
  IsNotEmpty,
  IsNumberString,
  IsOptional,
  MaxLength,
  MinLength,
  ValidateIf,
  ValidateNested,
} from 'class-validator';

export class LocationSectionEditInput {
  @IsOptional()
  @MaxLength(TEXT_AREA_LIMIT_LONG, { message: 'Description is too long.' })
  description?: string;

  @ValidateIf(o => !o.isExperienceAdvert)
  @IsEnum(ValidLocationEnvironmentTypes, {
    message: 'Please select the location type.',
  })
  environmentType: LocationEnvironmentTypes;

  @ValidateIf(o => o.shouldValidateAddress)
  @ValidateNested()
  @IsNotEmpty()
  @Type(() => LocationInput)
  address?: LocationInput;

  shouldValidateAddress?: boolean;
  isExperienceAdvert?: boolean;
}

export class LocationInput {
  @MinLength(1, { message: 'Please enter an address.' })
  @MaxLength(50, { message: 'Your address is too long.' })
  street: string;

  @MinLength(1, { message: 'City is blank.' })
  @MaxLength(50, { message: 'City name is too long.' })
  city: string;

  @IsOptional()
  apartment: string;

  @IsAlpha(undefined, { message: 'Invalid' })
  @MinLength(2, { message: 'Invalid' })
  @MaxLength(3, { message: 'Invalid' })
  state: string;

  @IsNumberString(undefined, { message: 'Invalid ZIP' })
  @MinLength(5, { message: 'Invalid ZIP' })
  @MaxLength(10, { message: 'Invalid ZIP' })
  zipCode: string;
}

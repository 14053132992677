import {
  MembershipPlanPurchase,
  MembershipPlanPurchaseQuery,
  MembershipPlanPurchaseQueryResponse,
} from '@/types';
import { GetGymPhysicalAccessType } from '@/types/Gym';
import { deepOmitNil } from '@/utils';
import { AxiosInstance } from 'axios';

export const endpoints = {
  getMembershipPlanPurchasesByMerchantMembership({
    membershipId,
  }: {
    membershipId: string;
  }) {
    return `/memberships/${membershipId}/membership-plan-purchases`;
  },
  getMembershipPlanPurchasesBySearchParams({
    membershipId,
  }: {
    membershipId: string;
  }) {
    return `/memberships/${membershipId}/membership-plan-purchases/search`;
  },
  getMembershipPlanPurchase({
    membershipPlanPurchaseId,
  }: {
    membershipPlanPurchaseId: string;
  }) {
    return `/membership-plan-purchases/${membershipPlanPurchaseId}`;
  },
  getMembershipPlanPurchaseInvoices({
    membershipPlanPurchaseId,
  }: {
    membershipPlanPurchaseId: string;
  }) {
    return `/membership-plan-purchases/${membershipPlanPurchaseId}/invoices`;
  },
  refundMembershipPlanPurchaseInvoice({
    membershipPlanPurchaseId,
  }: {
    membershipPlanPurchaseId: string;
  }) {
    return `/membership-plan-purchases/${membershipPlanPurchaseId}/refund`;
  },
  payMembershipPlanPurchaseInvoice({
    membershipPlanPurchaseId,
  }: {
    membershipPlanPurchaseId: string;
  }) {
    return `/membership-plan-purchases/${membershipPlanPurchaseId}/pay`;
  },
  cancelMembershipPlanPurchaseInvoice({
    membershipPlanPurchaseId,
  }: {
    membershipPlanPurchaseId: string;
  }) {
    return `/membership-plan-purchases/${membershipPlanPurchaseId}/void`;
  },
  terminatePlanPurchases({ planId }: { planId: string }) {
    return `/membership-plan/${planId}/terminate`;
  },
  pausePlanPurchases({ planId }: { planId: string }) {
    return `/membership-plan-purchases/${planId}/pause`;
  },
  terminateMembershipPurchases({ membershipId }: { membershipId: string }) {
    return `/membership/${membershipId}/terminate`;
  },
  terminateMembershipPlanPurchase({
    membershipPlanPurchaseId,
  }: {
    membershipPlanPurchaseId: string;
  }) {
    return `membership-plan-purchases/${membershipPlanPurchaseId}/terminate`;
  },
  getMemberPhysicalGymAccess({
    membershipPlanPurchaseId,
    consumerId,
  }: {
    membershipPlanPurchaseId: string;
    consumerId: string;
  }) {
    return `membership-plan-purchases/${membershipPlanPurchaseId}/consumers/${consumerId}/physical-access`;
  },
  messageOnePurchasedMember({
    membershipPlanPurchaseId,
    consumerId,
  }: {
    membershipPlanPurchaseId: string;
    consumerId: string;
  }) {
    return `membership-plan-purchases/${membershipPlanPurchaseId}/consumers/${consumerId}/messages`;
  },
  getPlanPurchasePastDueBalance({
    membershipPlanPurchaseId,
  }: {
    membershipPlanPurchaseId: string;
  }) {
    return `membership-plan-purchases/${membershipPlanPurchaseId}/balance`;
  },
  unblockManageMembers({
    membershipPlanPurchaseId,
  }: {
    membershipPlanPurchaseId: string;
  }) {
    return `membership-plan-purchases/${membershipPlanPurchaseId}/members/unblock`;
  },
};

export const membershipPlanPurchases = ({
  axiosInstance,
}: {
  axiosInstance: AxiosInstance;
}) => {
  return {
    getMembershipPlanPurchasesByMerchantMembership: async ({
      membershipId,
    }: {
      membershipId: string;
    }): Promise<MembershipPlanPurchase[]> => {
      const { data } = await axiosInstance.get(
        endpoints.getMembershipPlanPurchasesByMerchantMembership({
          membershipId,
        })
      );
      return data;
    },

    getMembershipPlanPurchasesBySearchParams: async ({
      membershipId,
      queryParams,
    }: {
      membershipId: string;
      queryParams: MembershipPlanPurchaseQuery;
    }): Promise<MembershipPlanPurchaseQueryResponse> => {
      const cleanParams: Partial<MembershipPlanPurchaseQuery> =
        deepOmitNil(queryParams);

      const { data } = await axiosInstance.get(
        endpoints.getMembershipPlanPurchasesBySearchParams({ membershipId }),
        {
          params: cleanParams,
        }
      );

      return data;
    },

    getMembershipPlanPurchase: async ({
      membershipPlanPurchaseId,
    }: {
      membershipPlanPurchaseId: string;
    }): Promise<MembershipPlanPurchase> => {
      const { data } = await axiosInstance.get(
        endpoints.getMembershipPlanPurchase({ membershipPlanPurchaseId })
      );
      return data;
    },
    getMembershipPlanPurchaseInvoices: async ({
      membershipPlanPurchaseId,
      limit,
      page,
    }: {
      membershipPlanPurchaseId: string;
      limit?: number;
      page?: number;
    }) => {
      const { data } = await axiosInstance.get(
        endpoints.getMembershipPlanPurchaseInvoices({
          membershipPlanPurchaseId,
        }),
        {
          params: { limit, page },
        }
      );
      return data;
    },
    refundMembershipPlanPurchaseInvoice: async ({
      membershipPlanPurchaseId,
      amount,
      invoiceId,
      reason,
    }: {
      membershipPlanPurchaseId: string;
      amount?: number;
      invoiceId: string;
      reason?: string;
    }) => {
      const { data } = await axiosInstance.post(
        endpoints.refundMembershipPlanPurchaseInvoice({
          membershipPlanPurchaseId,
        }),
        {
          amount,
          invoiceId,
          reason,
        }
      );
      return data;
    },
    payMembershipPlanPurchaseInvoice: async ({
      membershipPlanPurchaseId,
      invoiceId,
      amount,
    }: {
      membershipPlanPurchaseId: string;
      amount?: number;
      invoiceId: string;
      reason?: string;
    }) => {
      const { data } = await axiosInstance.post(
        endpoints.payMembershipPlanPurchaseInvoice({
          membershipPlanPurchaseId,
        }),
        {
          invoiceId,
          amount,
        }
      );
      return data;
    },
    cancelMembershipPlanPurchaseInvoice: async ({
      membershipPlanPurchaseId,
      invoiceId,
    }: {
      membershipPlanPurchaseId: string;
      amount?: number;
      invoiceId: string;
      reason?: string;
    }) => {
      const { data } = await axiosInstance.post(
        endpoints.cancelMembershipPlanPurchaseInvoice({
          membershipPlanPurchaseId,
        }),
        {
          invoiceId,
        }
      );
      return data;
    },
    terminateAllMembershipPlanPurchases: async ({
      planId,
    }: {
      planId: string;
    }): Promise<MembershipPlanPurchase[]> => {
      const { data } = await axiosInstance.put(
        endpoints.terminatePlanPurchases({ planId })
      );
      return data;
    },
    terminateAllMembershipPlansPurchases: async ({
      membershipId,
    }: {
      membershipId: string;
    }): Promise<MembershipPlanPurchase[]> => {
      const { data } = await axiosInstance.put(
        endpoints.terminateMembershipPurchases({ membershipId })
      );
      return data;
    },
    terminateOneMembershipPlanPurchase: async ({
      membershipPlanPurchaseId,
      isGracefulCancellation,
    }: {
      membershipPlanPurchaseId: string;
      isGracefulCancellation: boolean;
    }): Promise<MembershipPlanPurchase> => {
      const { data } = await axiosInstance.put(
        endpoints.terminateMembershipPlanPurchase({ membershipPlanPurchaseId }),
        {
          isGracefulCancellation,
        }
      );
      return data;
    },
    messageOnePurchasedMember: async ({
      membershipPlanPurchaseId,
      consumerId,
      message,
    }: {
      membershipPlanPurchaseId: string;
      consumerId: string;
      message: string;
    }): Promise<string> => {
      const { data } = await axiosInstance.post(
        endpoints.messageOnePurchasedMember({
          membershipPlanPurchaseId,
          consumerId,
        }),
        { emailMessage: message }
      );
      return data;
    },
    getMemberPhysicalGymAccess: async ({
      membershipPlanPurchaseId,
      consumerId,
    }: {
      membershipPlanPurchaseId: string;
      consumerId: string;
    }): Promise<GetGymPhysicalAccessType> => {
      const { data } = await axiosInstance.get(
        endpoints.getMemberPhysicalGymAccess({
          membershipPlanPurchaseId,
          consumerId,
        })
      );
      return data;
    },
    getPlanPurchasePastDueBalance: async ({
      membershipPlanPurchaseId,
    }: {
      membershipPlanPurchaseId: string;
    }): Promise<{ balance: number }> => {
      const { data } = await axiosInstance.get(
        endpoints.getPlanPurchasePastDueBalance({ membershipPlanPurchaseId })
      );
      return data;
    },
    unblockManageMembers: async ({
      membershipPlanPurchaseId,
    }: {
      membershipPlanPurchaseId: string;
    }) => {
      const { data } = await axiosInstance.patch(
        endpoints.unblockManageMembers({ membershipPlanPurchaseId })
      );
      return data;
    },
    pauseResumeMembershipPlanPurchases: async ({
      planId,
      isPause,
    }: {
      planId: string;
      isPause: boolean;
    }): Promise<MembershipPlanPurchase[]> => {
      const { data } = await axiosInstance.put(
        endpoints.pausePlanPurchases({ planId }),
        {
          pauseCollection: isPause ? { behavior: 'void' } : { resume: true },
        }
      );
      return data;
    },
  };
};

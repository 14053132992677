import { MembersDataTable } from '@/types';
import {
  Box,
  Button,
  ChevronDownIcon,
  Typography,
} from '@silverstein-properties/inspirelabs-ui';
import { createColumnHelper } from '@tanstack/react-table';

export const getMembersTableColumns = (
  onDetailsClick?: (id: string) => void
) => {
  const columnHelper = createColumnHelper<MembersDataTable>();

  return [
    columnHelper.display({
      id: 'chevron',
      cell: ({ row }) => (
        <>
          {row.getCanExpand() ? (
            <ChevronDownIcon
              width={24}
              onClick={row.getToggleExpandedHandler()}
              style={{
                cursor: 'pointer',
                transform: row.getIsExpanded()
                  ? 'rotate(180deg)'
                  : 'rotate(0deg)',
                transition: 'transform 0.3s ease-in-out',
              }}
            />
          ) : null}
        </>
      ),
      size: 24,
    }),

    columnHelper.accessor('name', {
      header: () => (
        <Typography variant="labelMedium" color="primary.medium">
          Name
        </Typography>
      ),
      cell: cellContext => (
        <>
          {cellContext.row.depth > 0 ? (
            <Typography variant="body2">{cellContext.getValue()}</Typography>
          ) : (
            <Box>
              <Typography variant="subtitle2">
                {cellContext.getValue()}
              </Typography>
              <Typography variant="body3" color="primary.medium">
                Primary member
              </Typography>
            </Box>
          )}
        </>
      ),
      size: 250,
    }),

    columnHelper.accessor('plan', {
      header: () => (
        <Typography variant="labelMedium" color="primary.medium">
          Plan
        </Typography>
      ),
      cell: cellContext => (
        <Typography variant="body3">{cellContext.getValue()}</Typography>
      ),
    }),

    columnHelper.accessor('totalMembers', {
      header: () => (
        <Typography variant="labelMedium" color="primary.medium">
          Members
        </Typography>
      ),
      cell: cellContext => (
        <Typography variant="body3" display="flex" justifyContent="flex-end">
          {cellContext.getValue()}
        </Typography>
      ),
    }),

    columnHelper.accessor('payment', {
      header: () => (
        <Typography variant="labelMedium" color="primary.medium">
          Payment
        </Typography>
      ),
      cell: cellContext => (
        <Typography variant="body3" display="flex" justifyContent="flex-end">
          {cellContext.getValue()}
        </Typography>
      ),
    }),

    columnHelper.display({
      id: 'statusDisplay',
      header: () => (
        <Typography variant="labelMedium" color="primary.medium">
          Status
        </Typography>
      ),
      cell: cellContext => (
        <Box textAlign="right">
          <Typography
            variant="body3"
            color={cellContext.row.original.statusColor}
          >
            {cellContext.row.original.isFrozen
              ? 'On hold'
              : cellContext.row.original.status}
          </Typography>
          {cellContext.row.original.activeUntil ? (
            <Typography
              variant="body3"
              color="primary.medium"
              textAlign="right"
            >
              {cellContext.row.original.activeUntil}
            </Typography>
          ) : null}
        </Box>
      ),
    }),

    columnHelper.accessor('id', {
      header: '',
      cell: info =>
        info.row.depth === 0 && onDetailsClick ? (
          <Button
            variant="outlined"
            size="small"
            onClick={() => onDetailsClick(info.getValue())}
            sx={{ float: 'right' }}
          >
            Details
          </Button>
        ) : null,
      size: 80,
      enableSorting: false,
    }),
  ];
};

import { Grid, styled } from '@silverstein-properties/inspirelabs-ui';

export const TableWrapper = styled(Grid)(({ theme }) => ({
  '& th': {
    backgroundColor: theme.palette.background.paper,
  },
  '& td': {
    padding: theme.spacers.m,
  },
}));

import { MerchantPaymentStatement, MonthlyStatement } from '@/types';
import { AxiosInstance } from 'axios';

export const endpoints = {
  paymentStatements: '/payment-statements',
  getMerchantPaymentStatements({ merchantId }: { merchantId: number }) {
    return `/payment-statements/merchants/${merchantId}`;
  },
  getPaymentStatement({ paymentStatementId }: { paymentStatementId: number }) {
    return `/payment-statements/${paymentStatementId}`;
  },
};

export const paymentStatements = ({
  axiosInstance,
}: {
  axiosInstance: AxiosInstance;
}) => {
  return {
    getMerchantPaymentStatements: async ({
      merchantId,
    }: {
      merchantId: number;
    }): Promise<MerchantPaymentStatement> => {
      const { data } = await axiosInstance.get(
        endpoints.getMerchantPaymentStatements({ merchantId })
      );
      return data;
    },
    getPaymentStatement: async ({
      paymentStatementId,
    }: {
      paymentStatementId: number;
    }): Promise<MonthlyStatement> => {
      const { data } = await axiosInstance.get(
        endpoints.getPaymentStatement({ paymentStatementId })
      );
      return data;
    },
  };
};

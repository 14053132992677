import usePartialCreateExperienceMutation from '@/hooks/mutations/usePartialCreateExperienceMutation';
import { BookingMethod, Experience } from '@/types';
import {
  CloseIcon,
  Dialog,
  List,
  Stack,
  Typography,
} from '@silverstein-properties/inspirelabs-ui';
import { useNavigate } from 'react-router';
import {
  CloseButton,
  StyledListItem,
  StyledListItemButton,
} from './SelectBookingTypeDialog.styles';
import { useFeatureFlags } from '@/hooks';
import { DialogContent, DialogTitle, Grid } from '@mui/material';
import {
  BOOKING_TYPE_DIALOG_OPTIONS,
  SelectBookingTypeDialogType,
} from '@/constants/selectBookingTypeDialogConstants';

const SelectBookingTypeDialog = ({
  open,
  onCloseModal,
}: {
  open: boolean;
  onCloseModal: () => void;
}) => {
  const {
    isBookingRequestEnabled,
    isCateringEnabled,
    isAdvertEnabled,
    isSpaceEnabled,
    isServiceEnabled,
  } = useFeatureFlags();
  const navigate = useNavigate();
  const { mutateAsync } = usePartialCreateExperienceMutation();
  const onClickBookingType = async ({
    bookingMethod,
    productType,
  }: SelectBookingTypeDialogType) => {
    let customData: Partial<Experience> = {};
    switch (bookingMethod) {
      case BookingMethod.REQUEST:
        customData = {
          groupBookingsAllowed: true,
        };
        break;
    }

    const newExperience = await mutateAsync({
      data: {
        bookingMethods: [bookingMethod],
        type: productType,
        ...customData,
        buildingIds: [],
      },
    });
    navigate(`/experiences/${newExperience.id}`, {
      state: { from: document.location.pathname },
    });
    onCloseModal();
  };

  const bookingTypeOptions = BOOKING_TYPE_DIALOG_OPTIONS({
    isAdvertEnabled,
    isBookingRequestEnabled,
    isCateringEnabled,
    isSpaceEnabled,
    isServiceEnabled,
  }).map((option, i) => (
    <StyledListItem
      disablePadding
      sx={{ marginTop: i === 0 ? undefined : 2 }}
      key={option.dataTestId}
      onClick={() => {
        onCloseModal();
      }}
    >
      <StyledListItemButton
        onClick={() => {
          onClickBookingType(option);
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs>
            <Typography variant="subtitle1" data-testid={option.dataTestId}>
              {option.title}
            </Typography>
            <Typography variant="body2" color="primary.medium">
              {option.description}
            </Typography>
          </Grid>
          <Grid item xs="auto">
            <option.icon width={64} />
          </Grid>
        </Grid>
      </StyledListItemButton>
    </StyledListItem>
  ));

  return (
    <Dialog
      open={open}
      onClose={() => onCloseModal()}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        sx: {
          maxWidth: '500px',
          width: '100%',
          maxHeight: '600px',
          paddingBottom: ({ spacing }) => spacing(3),
          borderRadius: ({ spacing }) => spacing(3),
        },
      }}
    >
      <DialogTitle>
        <Stack flexDirection="row" justifyContent="center" paddingY={1}>
          <CloseButton size="small" onClick={onCloseModal}>
            <CloseIcon width={30} />
          </CloseButton>
          <Typography variant="subtitle2">Select Product Type</Typography>
        </Stack>
      </DialogTitle>
      <DialogContent sx={{ pb: 0 }}>
        <List>{bookingTypeOptions}</List>
      </DialogContent>
    </Dialog>
  );
};
export { SelectBookingTypeDialog };

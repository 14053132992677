// Currently supported Product Types.
// List of concrete classes of objects the platform supports
export enum ProductTypes {
  ONE_TIME_EVENT = 'one-time-event', // Should be renamed to Experience
  HOUSEKEEPING_SERVICE = 'housekeeping_service',
  DOG_WALKING_SERVICE = 'dog_walking_service', // Creates booking request instead of bookings
  FOOD_SERVICE = 'food_service',
  ADVERT = 'advert',
  SPACE = 'space',
  GENERAL_SERVICE = 'general_service',
}

import {
  Box,
  ChevronLeftIcon,
  ChevronRightIcon,
  Container,
  DataTable,
  DataTableEmpty,
  Grid,
  Typography,
  useTheme,
} from '@silverstein-properties/inspirelabs-ui';
import { useMemo, useState } from 'react';
import { CalendarFilter, EVENTS_FILTERS } from '../CalendarFilter';
import { useGetAllMerchantBookings } from '@/hooks/queries/useBookings';
import {
  getCoreRowModel,
  PaginationState,
  useReactTable,
} from '@tanstack/react-table';
import { getTableColumns } from './CustomersTable.columns';
import { useExperiences } from '@/hooks';
import { ExperienceStatus } from '@/types';
import {
  filterAndSortCalendarViewExperience,
  formatDateWithDayName,
  formatDateWithoutDay,
} from '@/utils';
import { OptionType } from '../CalendarFilter/Autocomplete';
import { DataTableLoading, Pagination } from '@/components';
import { DEFAULT_SEARCH_PARAMS } from '@/constants';
import { addDays, addMonths } from 'date-fns';
import { TableWrapper } from './MerchantCustomers.styles';

export const MerchantCustomers = ({
  onChangeSelectedFilter,
}: {
  onChangeSelectedFilter: (filter: string) => void;
}) => {
  const theme = useTheme();

  const [selectedProductIds, setSelectedProductIds] = useState<string[]>([]);
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: DEFAULT_SEARCH_PARAMS.PAGE,
    pageSize: DEFAULT_SEARCH_PARAMS.PAGE_SIZE,
  });
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());

  const { data: experiences } = useExperiences();

  const productOptions: OptionType[] = useMemo(() => {
    const schedulableExperiences = filterAndSortCalendarViewExperience(
      experiences || [],
      [ExperienceStatus.Approved, ExperienceStatus.Published]
    );
    return schedulableExperiences.map(experience => ({
      value: experience.id,
      title: experience.title,
    }));
  }, [experiences]);

  const { data: merchantBookings, isLoading } = useGetAllMerchantBookings({
    limit: pagination.pageSize,
    page: pagination.pageIndex,
    startDate: selectedDate.toISOString(),
    productIds: selectedProductIds,
  });

  const pageCount = merchantBookings?.metadata?.totalItems
    ? Math.ceil(merchantBookings?.metadata?.totalItems / pagination.pageSize)
    : -1;

  const table = useReactTable({
    data: merchantBookings?.data || [],
    columns: getTableColumns(),
    pageCount: pageCount,
    state: {
      pagination,
    },
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    columnResizeMode: 'onChange',
    manualPagination: true,
  });

  const handleChangeDay = (type: 'prev' | 'next') => {
    setSelectedDate(prevDate => addDays(prevDate, type === 'prev' ? -1 : 1));
  };

  const handleChangeMonth = (type: 'prev' | 'next') => {
    setSelectedDate(prevDate => addMonths(prevDate, type === 'prev' ? -1 : 1));
  };

  return (
    <Container sx={{ paddingY: theme.spacers.xxl4 }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          mb: theme.spacers.xl,
          alignItems: 'center',
          height: 56,
        }}
      >
        <Typography variant="h4">Schedule</Typography>
        <Box
          sx={{ display: 'flex', alignItems: 'center', gap: theme.spacers.s }}
        >
          {/* <DocumentIcon width={24} height={24} />
          <Link href="" variant="body2">
            Download PDF
          </Link> */}
        </Box>
      </Box>
      <Box
        sx={{
          position: 'relative',
          height: 55,
          display: 'grid',
          gridTemplateColumns: '300px auto 300px',
          mb: theme.spacers.l,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: theme.spacing(1),
          }}
        >
          <ChevronLeftIcon
            width={27}
            height={27}
            strokeWidth={2}
            onClick={() => handleChangeMonth('prev')}
          />
          <ChevronRightIcon
            width={27}
            height={27}
            strokeWidth={2}
            onClick={() => handleChangeMonth('next')}
          />
          <Typography
            sx={{ ml: theme.spacers.s }}
            variant="h6"
            fontWeight={theme.typography.fontWeightMedium}
          >
            {formatDateWithoutDay(selectedDate)}
          </Typography>
        </Box>
        <CalendarFilter
          onChangeSelectedProducts={setSelectedProductIds}
          onChangeSelectedFilter={onChangeSelectedFilter}
          products={productOptions}
          selectedFilter={EVENTS_FILTERS.CUSTOMERS}
        />
        <Box />
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: theme.spacers.m }}>
        <Typography
          sx={{ mr: theme.spacers.m }}
          variant="body1"
          fontWeight={theme.typography.fontWeightMedium}
        >
          {formatDateWithDayName(selectedDate)}
        </Typography>
        <ChevronLeftIcon
          width={24}
          height={24}
          onClick={() => handleChangeDay('prev')}
        />
        <ChevronRightIcon
          width={24}
          height={24}
          onClick={() => handleChangeDay('next')}
        />
      </Box>

      <Grid container item xs={12}>
        <TableWrapper
          item
          xs={12}
          sx={{
            '& th': {
              backgroundColor: theme.palette.background.paper,
            },
            '& td': {
              padding: theme.spacers.m,
            },
          }}
        >
          <DataTable table={table} rightAlignedColumns={[]} />
          <DataTableLoading show={isLoading} numberOfSkeletons={10} />
          <DataTableEmpty
            show={
              !isLoading &&
              (!merchantBookings || merchantBookings?.data.length === 0)
            }
          />
          <Pagination
            show={true}
            table={table}
            totalItems={merchantBookings?.metadata?.totalItems || 0}
          />
        </TableWrapper>
      </Grid>
    </Container>
  );
};

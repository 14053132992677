import {
  Grid,
  Typography,
  Button,
  useTheme,
  IconBlock,
} from '@silverstein-properties/inspirelabs-ui';
import { useNavigate } from 'react-router-dom';
import { StyledContainer } from './MerchantMembershipEmptyState.styles';

export const MerchantMembershipEmptyState = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <StyledContainer>
      <Typography variant="h4" marginBottom={theme.spacers.xxl}>
        Bundle products into membership packages
      </Typography>

      <Grid
        container
        item
        columnSpacing={theme.spacers.xl}
        maxWidth="904px"
        marginBottom={theme.spacers.xxl2}
      >
        <Grid item xs={4}>
          <IconBlock
            align="center"
            icon="/images/package.svg"
            title="Create a package"
            body="Create memberships plans with tiers and pricing points"
            bodySize="body2"
          />
        </Grid>
        <Grid item xs={4}>
          <IconBlock
            align="center"
            icon="/images/growth.svg"
            title="Connect products"
            body="Connect the product you would like to include in a membership"
            bodySize="body2"
          />
        </Grid>
        <Grid item xs={4}>
          <IconBlock
            align="center"
            icon="/images/hub.svg"
            title="Publish"
            body="and start hosting in no time!"
            bodySize="body2"
          />
        </Grid>
      </Grid>

      <Button onClick={() => navigate('/memberships/create')}>
        Create membership
      </Button>
    </StyledContainer>
  );
};

import { ISO_DATE_FORMAT_REGEX } from '@/constants';
import {
  format,
  parse,
  parseISO,
  startOfDay,
  isEqual,
  set,
  isToday,
  isTomorrow,
} from 'date-fns';

/**
 * Returns the current date and time
 *
 * @returns Returns a Date object
 */
export const now = () => new Date();

/**
 * Formats date in en-US format. i.e. February 27, 2023
 *
 * @param date Date in either string or Date object
 * @param isUTC boolean - optional - true if input is UTC format eg: 2023-05-30 06:00:00.000 -0400
 * @returns Returns a formatted date string
 */
export const formatDate = (date: string | Date, isUTC?: boolean) => {
  const dateOptions: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };
  if (isUTC) {
    dateOptions['timeZone'] = 'UTC';
  }
  return new Date(date).toLocaleDateString('en-US', dateOptions);
};

/**
 * Formats a given date into a human-readable string.
 *
 * - If the date is today, it returns `"Today, Feb 24"`.
 * - If the date is tomorrow, it returns `"Tomorrow, Feb 25"`.
 * - Otherwise, it returns the formatted date `"Feb 20"`.
 *
 * @param date - The date to format (can be a `string` or `Date`).
 * @returns A formatted string representation of the date.
 */
export const formatDateWithDayName = (date: string | Date): string => {
  const target = new Date(date);

  if (isToday(target)) {
    return `Today, ${format(target, 'MMM d')}`;
  }
  if (isTomorrow(target)) {
    return `Tomorrow, ${format(target, 'MMM d')}`;
  }

  return format(target, 'MMM d');
};

export const formatDateWithDayOfWeek = (
  date: string | Date,
  isUTC?: boolean
) => {
  const dateOptions: Intl.DateTimeFormatOptions = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };
  if (isUTC) {
    dateOptions['timeZone'] = 'UTC';
  }
  return new Date(date).toLocaleDateString('en-US', dateOptions);
};

export const formatDateWithoutYear = (date: string | Date, isUTC?: boolean) => {
  const dateOptions: Intl.DateTimeFormatOptions = {
    month: 'long',
    day: 'numeric',
  };
  if (isUTC) {
    dateOptions['timeZone'] = 'UTC';
  }
  return new Date(date).toLocaleDateString('en-US', dateOptions);
};

export const formatDateWithoutDay = (date: string | Date, isUTC?: boolean) => {
  const dateOptions: Intl.DateTimeFormatOptions = {
    month: 'long',
    year: 'numeric',
  };
  if (isUTC) {
    dateOptions['timeZone'] = 'UTC';
  }
  return new Date(date).toLocaleDateString('en-US', dateOptions);
};

/**
 *
 * @param date ate in either string or Date object
 * @param isUTC boolean - optional - true if input is UTC format eg: 2023-05-30 06:00:00.000 -0400
 * @returns the month in english
 */
export const formatMonth = (date: string | Date, isUTC?: boolean) => {
  const dateOptions: Intl.DateTimeFormatOptions = {
    month: 'long',
  };
  if (isUTC) {
    dateOptions['timeZone'] = 'UTC';
  }
  return new Date(date).toLocaleDateString('en-US', dateOptions);
};

/**
 * Formats time in en-US in short format. i.e. 9:00 AM
 *
 * @param date Date in either string or Date object
 * @param isUTC boolean - optional - true if input is UTC format eg: 2023-05-30 06:00:00.000 -0400
 * @returns Returns a formatted time string
 */
export const formatTime = (date: string | Date, isUTC?: boolean) => {
  const dateOptions: Intl.DateTimeFormatOptions = {
    timeStyle: 'short',
  };
  if (isUTC) {
    dateOptions['timeZone'] = 'UTC';
  }
  return new Intl.DateTimeFormat('en-US', dateOptions).format(new Date(date));
};

/**
 * Formats the date string with date and time combo. I.E.
 * Apr 29, 2023, 12:00 AM. https://date-fns.org/v2.29.3/docs/format
 *
 * @param date Date in string format
 * @returns
 */
export const formatDateForExperienceView = (date: string) => {
  return format(new Date(date), 'PPp');
};

/**
 * Formats the date string to return the date only, for use in display in editing a listing -
 * example -> input: "2023-04-05T12:59:07.023Z", output: "2023-04-05"
 * https://date-fns.org/v2.29.3/docs/format
 *
 * @param date Date in string format
 * @param formatStyle optional - default 'yyyy-MM-dd'
 * @returns
 */
export const formatDateShortForm = (
  date: string | Date,
  formatStyle = 'yyyy-MM-dd'
) => {
  return format(new Date(date), formatStyle);
};

export const tomorrow = new Date();
tomorrow.setDate(tomorrow.getDate() + 1); // default date to tomorrow

const formatHours = (hour: number): string => {
  return hour.toString().length === 1 && hour !== 0
    ? `0${hour}`
    : hour === 0
    ? ''
    : `${hour}`;
};

const formatMinutes = (minute: number) => {
  return minute === 0 ? '00' : minute;
};

/**
 * Help to map our BE values on event duration to the label of our dropdown options
 * example: input is 2023-03-30T13:30:00.000Z, output is 1330
 * @param inputDate the date time that a listing starts or ends
 * @returns number
 */
export const formatTimeToDropdown = (
  inputDate: Date | undefined
): number | undefined => {
  if (!inputDate) {
    return undefined;
  }
  return parseInt(
    `${formatHours(inputDate.getHours())}${formatMinutes(
      inputDate.getMinutes()
    )}`,
    10
  );
};

/**
 * Validate if the input is a valid ISO date string
 * @param inputDate the date time that a listing starts or ends
 * @returns boolean
 * */
// Lint rule disabled to allow any value to be compared
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isIsoDateString = (value: any): boolean => {
  return (
    value && typeof value === 'string' && ISO_DATE_FORMAT_REGEX.test(value)
  );
};

/**
 * Transform all ISO date strings in an object to Date objects
 * @param body  the object to be transformed
 * @returns  the transformed object
 */
// Disabled so that it can literally transform any object
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const transformDates = (body: any) => {
  if (body === null || body === undefined || typeof body !== 'object') {
    return body;
  }

  for (const key of Object.keys(body)) {
    const value = body[key];
    if (isIsoDateString(value)) {
      body[key] = parseISO(value);
    } else if (typeof value === 'object') {
      transformDates(value);
    }
  }
};

/**
 * Convert the date string (e.g. 2024-03-30) to a local date object (e.g. Sat Mar 30 2024 00:00:00 GMT-0400 (Eastern Daylight Time))
 * @param {string} dateString - The date string in the format "YYYY-MM-DD".
 * @returns {Date} - The local date object.
 */
export const convertToLocalDate = (dateString: string) => {
  const [year, month, day] = dateString
    .split('-')
    .map(num => parseInt(num, 10));

  // Create a date object for the local time zone by specifying the year, month, and day
  // Note: JavaScript months are 0-based, so subtract 1 from the month value
  const date = new Date(year, month - 1, day);

  // Explicitly set the time to the start of the day to avoid any DST issues
  date.setHours(0, 0, 0, 0);

  return date;
};

/**
 * Check to see if a date string matches a date time string (e.g. '3-31-2024' vs 2024-03-30T12:00:00.000Z should be false)
 * @param Date|string dateTime - The date string in the format "YYYY-MM-DD".
 * @param dateString - The date string in the format "YYYY-MM-DD".
 * @returns boolean - whether the date matches the date time
 */
export const doesDateMatchDateTime = (
  dateTime: Date | string,
  dateString: string
) => {
  const date1 = new Date(dateTime);
  const date2 = parseISO(dateString);

  return isEqual(startOfDay(date1), startOfDay(date2));
};

/**
 *
 * @param Date dateTimeString - a date time
 * @param dateString string - a date string
 * @returns boolean - a new dateTime, with the date replaced
 */
export const replaceDateInDateTime = (
  dateTimeString: Date,
  dateString: string
) => {
  const originalDateTime = new Date(dateTimeString);

  const newDate = parse(dateString, 'yyyy-MM-dd', new Date());

  const combinedDateTime = set(newDate, {
    hours: originalDateTime.getHours(),
    minutes: originalDateTime.getMinutes(),
    seconds: originalDateTime.getSeconds(),
  });

  return new Date(combinedDateTime.toISOString());
};

/**
 *
 * @param dateString string
 * @param hours number
 * @param minutes number
 * @returns Date
 * transform dateString to formated Date type at set given time
 */
export const parseStringDateToTime = (
  dateString: string,
  hours = 0,
  minutes = 0
): Date => {
  const dateLocaleString = formatDate(dateString, true);
  return set(new Date(dateLocaleString), { hours: hours, minutes: minutes });
};

/**
 * checks if a given time is set in the past
 *
 * @param time
 * @returns boolean
 */
export const isDateInPast = (time: string | Date) => {
  if (!time) {
    return false;
  }

  const actualDate = new Date(time);

  return now() > actualDate;
};

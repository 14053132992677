import { Listing } from './Listing';
import { Location } from './Location';
import { RefundReasons } from './MembershipPlanPurchase';
import { PaymentStatus } from './Payments';

export interface BookingConsumer {
  firstName: string;
  lastName: string;
  email: string;
  image_url?: string;
}

export interface ConsumerPricing {
  subTotal: number; // merchantPrice + feeAmount (inclusive)
  discounts: number;
  taxRates: number;
  total: number;
}

export enum BookingMethod {
  // Book the event and charge the consumer right away
  INSTANT = 'instant',
  // Booking request with the merchant with quote for consumer to accept/refuse.
  // Once the quote is accepted, that's when consumer gets charged
  REQUEST = 'request',
  // Book the delivery order and charge the consumer right away
  DELIVERY = 'delivery',
  // Advert campaign with a link to details page provided by merchant
  ADVERT = 'advert',
  // Book a space for a specific date and time
  SPACE = 'space',
  // TODO: Remove this once we have a better way to handle this
  // TODO: Combine BookingMethod and Product Types into one enum
  // I.E. BookingMethod.INSTANT and ProductTypes.GENERAL_SERVICE.
  // Book a service using instant booking method
  GENERAL_SERVICE = 'general_service',
}

export interface Booking {
  id: string;
  consumerId: string;
  consumer: BookingConsumer;
  listingSnapshot: Listing;
  paymentStatus: PaymentStatus;
  pricing?: ConsumerPricing;
  nbOfAdditionalGuests: number;

  createdUserId: string;
  createdAt: string;
  updatedUserId?: number;
  updatedAt?: string;
  deletedUserId?: number;
  deletedAt?: string;
  status?: string;
  location?: Location;
}

export interface RefundBookingDto {
  amount: number;
  reason: RefundReasons;
  notes?: string;
}

import { ApiClient } from '@/api/apiClient';
import { Booking, Pagination } from '@/types';
import { useQuery } from '@tanstack/react-query';
import { useAuth } from '../useAuth';

export const useGetAllMerchantBookings = (
  {
    productIds,
    startDate,
    limit,
    page,
  }: {
    productIds: string[];
    startDate?: string;
    limit?: number;
    page?: number;
  },
  enabled = true
) => {
  const apiClient = ApiClient();
  const { data: user } = useAuth();

  const sortedProductIds = productIds.sort().join(','); // needed for react query to detect changes and avoid unnecessary cache invalidation

  return useQuery<Pagination<Booking>>(
    ['merchantCustomers', sortedProductIds, startDate, limit, page],
    async () =>
      apiClient.bookings.getMerchantBookings({
        merchantId: user?.primaryMerchantId ?? '',
        startDate: startDate,
        limit: limit,
        page: page,
        productIds: productIds,
      }),
    { enabled: !!user && enabled, retry: false, keepPreviousData: true }
  );
};

import { BookingMethod, ProductTypes } from '@/types';
import {
  ApplicantsIcon,
  CateringIcon,
  ConciergeIcon,
  LinkIcon,
  ScheduleIcon,
  SpacesIcon,
} from '@silverstein-properties/inspirelabs-ui';
import { ForwardRefExoticComponent, RefAttributes, SVGProps } from 'react';

export interface SelectBookingTypeDialogType {
  bookingMethod: BookingMethod;
  productType: ProductTypes;
  title: string;
  description: string;
  dataTestId: string;
  icon: ForwardRefExoticComponent<
    Omit<SVGProps<SVGSVGElement>, 'ref'> & RefAttributes<SVGSVGElement>
  >;
}

const BookingTypeDialogIconMap: Record<
  string,
  ForwardRefExoticComponent<
    Omit<SVGProps<SVGSVGElement>, 'ref'> & RefAttributes<SVGSVGElement>
  >
> = {
  ApplicantsIcon,
  CateringIcon,
  LinkIcon,
  ScheduleIcon,
  SpacesIcon,
  ConciergeIcon,
};

type BookingTypeDialogOptionsProps = {
  isServiceEnabled: boolean;
  isBookingRequestEnabled: boolean;
  isCateringEnabled: boolean;
  isAdvertEnabled: boolean;
  isSpaceEnabled: boolean;
};

export const BOOKING_TYPE_DIALOG_OPTIONS = ({
  isServiceEnabled,
  isAdvertEnabled,
  isBookingRequestEnabled,
  isCateringEnabled,
  isSpaceEnabled,
}: BookingTypeDialogOptionsProps): SelectBookingTypeDialogType[] => [
  {
    bookingMethod: BookingMethod.INSTANT,
    productType: ProductTypes.ONE_TIME_EVENT,
    title: 'Instant Booking',
    description:
      'I provide a fixed schedule where customers can book instantly',
    dataTestId: 'bookingTypeInstant',
    icon: BookingTypeDialogIconMap['ScheduleIcon'],
  },
  ...(isServiceEnabled
    ? [
        {
          bookingMethod: BookingMethod.GENERAL_SERVICE,
          productType: ProductTypes.GENERAL_SERVICE,
          title: 'Services',
          description: 'Customers can book a service',
          dataTestId: 'bookingTypeService',
          icon: BookingTypeDialogIconMap['ConciergeIcon'],
        },
      ]
    : []),
  ...(isBookingRequestEnabled
    ? [
        {
          bookingMethod: BookingMethod.REQUEST,
          productType: ProductTypes.ONE_TIME_EVENT,
          title: 'Booking Request',
          description:
            'Customers will contact me to set up final scope of service, date and time.',
          dataTestId: 'bookingTypeRequest',
          icon: BookingTypeDialogIconMap['ApplicantsIcon'],
        },
      ]
    : []),
  ...(isCateringEnabled
    ? [
        {
          bookingMethod: BookingMethod.DELIVERY,
          productType: ProductTypes.FOOD_SERVICE,
          title: 'Catering',
          description: 'Customers can choose amongst menu items from Square',
          dataTestId: 'bookingTypeCatering',
          icon: BookingTypeDialogIconMap['CateringIcon'],
        },
      ]
    : []),
  ...(isAdvertEnabled
    ? [
        {
          bookingMethod: BookingMethod.ADVERT,
          productType: ProductTypes.ADVERT,
          title: 'Advert',
          description:
            'Customers can see my advertisement before being redirected to my link',
          dataTestId: 'bookingTypeAdvert',
          icon: BookingTypeDialogIconMap['LinkIcon'],
        },
      ]
    : []),
  ...(isSpaceEnabled
    ? [
        {
          bookingMethod: BookingMethod.SPACE,
          productType: ProductTypes.SPACE,
          title: 'Spaces',
          description: 'You offer commercial spaces',
          dataTestId: 'bookingTypeSpaces',
          icon: BookingTypeDialogIconMap['SpacesIcon'],
        },
      ]
    : []),
];

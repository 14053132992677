export const defaultFeatureFlags = {
  'merchant-scheduling': true,
  'merchant-refunding': false,
  'cancel-experience-event': false,
  'message-guests': false,
  'communication-inbox': false,
  'edit-published-listing': false,
  'edit-button-in-experience-details-page': true,
  'edit-location': false,
  'merchant-statements': false,
  'statement-event-details': false,
  'statement-export-to-pdf': false,
  'statement-dashboard-cards': false,
  'merchant-scheduling-list-view': true,
  'merchant-memberships': true,
  'participants-export-to-csv': true,
  'edit-button-in-membership-details-page': true,
  'enable-2FA': false,
  'merchant-teams': false,
  'view-user-and-business-profile': false,
  'cancel-primary-member': false,
  'edit-product-capacity': false,
  'recurring-events': true,
  'members-section': false,
  'close-sign-ups': false,
  'skip-image-validation': false,
  'booking-requests': false,
  catering: false,
  advert: false,
  'media-service-merchant-images': true,
  'booking-request-with-membership': false,
  'terminate-membership': false,
  'delete-membership': false,
  space: false,
  service: false,
};

import {
  Box,
  Grid,
  Typography,
  Card,
} from '@silverstein-properties/inspirelabs-ui';
import { StyledCardContent } from './DetailSection.styles';
import { formatDateForExperienceView } from '@/utils';
import { ReactNode, isValidElement } from 'react';
import { Membership } from '@/types/MerchantMembership';

export type DetailSectionPropsType = {
  children: ReactNode;
  id: string;
  title: ReactNode;
  buttonLabel: string;
  membership: Membership;
  onlyChildren?: boolean;
  showTimestamp?: boolean;
  onEditClick: (screenName: string) => void;
};

export const DetailSection = ({
  children,
  id,
  title,
  membership,
  buttonLabel,
  onlyChildren = false,
  showTimestamp = true,
  onEditClick,
}: DetailSectionPropsType) => {
  return onlyChildren ? (
    <>
      <Box id={id} />
      {children}
    </>
  ) : (
    <CardDetailSection
      id={id}
      title={title}
      buttonLabel={buttonLabel}
      membership={membership}
      showTimestamp={showTimestamp}
      onEditClick={onEditClick}
    >
      {children}
    </CardDetailSection>
  );
};

type HeaderDetailSectionPropsType = {
  title: ReactNode;
  showTimestamp?: boolean;
  membership: Membership;
};

const HeaderDetailSection = ({
  title,
  showTimestamp = true,
  membership,
}: HeaderDetailSectionPropsType) => {
  const formattedTimeStamp = membership.updatedAt
    ? 'Updated at ' + formatDateForExperienceView(membership.updatedAt)
    : membership.createdAt
    ? 'Created at ' + formatDateForExperienceView(membership.createdAt)
    : '';

  return (
    <Box>
      {isValidElement(title) ? (
        title
      ) : (
        <Typography variant="h5">{title}</Typography>
      )}
      {showTimestamp && (
        <Typography variant="body3">{formattedTimeStamp}</Typography>
      )}
    </Box>
  );
};

type CardDetailSectionPropsType = {
  children: ReactNode;
  id: string;
  title: ReactNode;
  membership: Membership;
  buttonLabel?: string;
  showTimestamp?: boolean;
  onEditClick: (screenName: string) => void;
};

const CardDetailSection = ({
  children,
  id,
  title,
  membership,
  showTimestamp = true,
}: CardDetailSectionPropsType) => {
  return (
    <Grid id={id} item xs={12}>
      <Card variant="outlined">
        <StyledCardContent sx={{}}>
          <Grid container>
            <HeaderDetailSection
              title={title}
              membership={membership}
              showTimestamp={showTimestamp}
            />
          </Grid>
          <Box sx={{ mt: 4 }}>{children}</Box>
        </StyledCardContent>
      </Card>
    </Grid>
  );
};

import { SERVICE_TYPE_CHOICES } from '@/constants';
import { BookingMethod, LocationEnvironmentTypes } from '@/types';

export const getServiceTypeFromOptions = (
  environmentType?: LocationEnvironmentTypes
) => {
  switch (environmentType) {
    case LocationEnvironmentTypes.INDOOR_CUSTOMER_LOCATION:
    case LocationEnvironmentTypes.OUTDOOR_CUSTOMER_LOCATION:
      return SERVICE_TYPE_CHOICES[
        LocationEnvironmentTypes.GENERIC_CUSTOMER_LOCATION
      ];
    case LocationEnvironmentTypes.OUTDOOR:
    case LocationEnvironmentTypes.INDOOR:
    case LocationEnvironmentTypes.COMMON_AREA:
    case LocationEnvironmentTypes.OUTDOOR_TOUR:
      return SERVICE_TYPE_CHOICES[
        LocationEnvironmentTypes.GENERIC_PROVIDER_LOCATION
      ];
    default:
      return SERVICE_TYPE_CHOICES.find(x => x.value === environmentType);
  }
};

/**
 * Evaluates whether to validate the address in the form depending
 * on some criteria
 * @param bookingMethods BookingMethod[]
 * @param environmentType LocationEnvironmentTypes
 * @returns Boolean
 */
export const isAddressEditAvailable = (
  bookingMethods?: BookingMethod[],
  environmentType?: LocationEnvironmentTypes
) => {
  // adverts never needs address
  if (bookingMethods?.includes(BookingMethod.ADVERT)) {
    return false;
  }

  // for all other booking methods address need depends on location type
  return ![
    LocationEnvironmentTypes.INDOOR_CUSTOMER_LOCATION,
    LocationEnvironmentTypes.OUTDOOR_CUSTOMER_LOCATION,
    LocationEnvironmentTypes.GENERIC_CUSTOMER_LOCATION,
  ].includes(environmentType || LocationEnvironmentTypes.NONE);
};

/**
 * Evaluates whether to add the description in the form depending
 * on some criteria
 * @param bookingMethods BookingMethod[]
 * @param environmentType LocationEnvironmentTypes
 * @returns Boolean
 */
export const isDescriptionEditAvailable = (
  bookingMethods?: BookingMethod[],
  environmentType?: LocationEnvironmentTypes
) => {
  if (bookingMethods?.includes(BookingMethod.DELIVERY)) {
    return false;
  }

  if (environmentType === LocationEnvironmentTypes.GENERIC_CUSTOMER_LOCATION) {
    return false;
  }

  return true;
};

import 'reflect-metadata'; // Required for Array validation in useForm
import React from 'react';
import ReactDOM from 'react-dom/client';
import './sass/main.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { ThemeProvider } from '@silverstein-properties/inspirelabs-ui';
import { queryClient, themeOptions } from './config';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { defaultFeatureFlags } from './constants';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const renderApp = async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_ID || '',
    flags: defaultFeatureFlags,
  });

  root.render(
    <React.StrictMode>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />
        <BrowserRouter>
          <LDProvider>
            <ThemeProvider themeOptions={themeOptions}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <App />
              </LocalizationProvider>
            </ThemeProvider>
          </LDProvider>
        </BrowserRouter>
      </QueryClientProvider>
    </React.StrictMode>
  );
};

renderApp();

// ***
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

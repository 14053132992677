import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { NavBarProps } from './Component';
import { useFeatureFlags } from '@/hooks';
import { routes } from '@/routing/routes';

const LANDING_BUTTONS = [
  { name: 'How it works', link: '/#how-it-works' },
  { name: 'Contact us', link: '/#contact-us' },
  // {name: 'Help', link: '/'},
];
const BUSINESS_BUTTONS = (
  isMerchantSchedulingEnabled: boolean,
  isCommunicationInboxEnabled: boolean,
  isMerchantStatementsEnabled: boolean,
  areMerchantMembershipsEnabled: boolean
) => [
  { name: 'Dashboard', link: '/dashboard' },
  // Hiding the link(s) below using the LD flag
  ...(isMerchantSchedulingEnabled
    ? [{ name: 'Schedule', link: '/calendar' }]
    : []),
  { name: 'Products', link: '/experiences' },
  ...(isCommunicationInboxEnabled
    ? [{ name: 'Messages', link: '/messages' }]
    : []),
  // {name: 'Users', link: '/users'},
  ...(isMerchantStatementsEnabled
    ? [{ name: 'Statements', link: routes.statements.main }]
    : []),
  ...(areMerchantMembershipsEnabled
    ? [{ name: 'Memberships', link: '/memberships' }]
    : []),
];

export function useHooks({ currentUser }: NavBarProps) {
  const defaultWindowSize =
    window.innerWidth > 854 ? 2 : window.innerWidth > 904 ? 1 : 0;
  const [windowSize, setWindowSize] = useState(defaultWindowSize);
  const {
    isMerchantSchedulingEnabled,
    isCommunicationInboxEnabled,
    isMerchantStatementsEnabled,
    areMerchantMembershipsEnabled,
  } = useFeatureFlags();

  const updateMedia = () => {
    setWindowSize(defaultWindowSize);
  };

  useEffect(() => {
    window.addEventListener('resize', updateMedia);
    return () => window.removeEventListener('resize', updateMedia);
  });

  const location = useLocation();
  let buttons = LANDING_BUTTONS;
  if (currentUser?.firstName) {
    buttons = BUSINESS_BUTTONS(
      isMerchantSchedulingEnabled,
      isCommunicationInboxEnabled,
      isMerchantStatementsEnabled,
      areMerchantMembershipsEnabled
    );
  }

  return {
    location,
    buttons,
    windowSize,
  };
}

import { Experience, Listing, ListingStatus } from '@/types';
import { formatPricing } from '@/utils';
import { ThemeOptions } from '@silverstein-properties/inspirelabs-ui';

export const getCalendarListingBackgroundColor = (
  listing: Listing,
  themeOptions: ThemeOptions
) => {
  if (listing?.status === ListingStatus.MERCHANT_CANCELLED) {
    return themeOptions!.palette!.error!.light;
  } else if (listing?.purchasedBy.length > 0) {
    return themeOptions!.palette!.secondary!.main;
  } else {
    return themeOptions!.palette!.secondary!.light;
  }
};

export const formatListing = (
  listings: Listing[],
  experiences: Experience[],
  themeOptions: ThemeOptions
) => {
  return (
    listings?.map(listing => ({
      id: listing.id || '',
      title:
        listing.basePrice?.totalAmount || listing.totalAmount
          ? formatPricing(listing.basePrice?.totalAmount || listing.totalAmount)
          : '',
      end: listing.endsAt || '',
      start: listing.startsAt || '',
      backgroundColor: getCalendarListingBackgroundColor(listing, themeOptions),
      borderColor:
        listing?.status === ListingStatus.MERCHANT_CANCELLED
          ? themeOptions?.palette?.error?.light
          : themeOptions?.palette?.secondary?.main,
      classNames:
        listing?.status === ListingStatus.MERCHANT_CANCELLED
          ? 'cancelled-listing'
          : '',
      nbOfGuests: listing?.purchasedBy?.length || 0,
      nbOfCancellations: 0,
      capacity:
        listing.capacity?.max ||
        experiences?.find(x => x.id === listing.productId)?.capacity?.max ||
        listing.productSnapshot?.capacity?.max ||
        0,
      productId: listing.productId,
      host: listing.host,
      status: listing.status,
      productSnapshot: listing.productSnapshot,
      isRecurringEvent: listing?.recurringEventId || null,
      hasBookings: listing.hasBookings,
    })) || []
  );
};

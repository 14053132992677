import {
  Booking,
  EventBookingSummary,
  Pagination,
  RefundBookingDto,
} from '@/types';
import { AxiosInstance } from 'axios';

export const endpoints = {
  bookings({ bookingId }: { bookingId: string }) {
    return `/bookings/${bookingId}`;
  },
  bookingsRefund({ bookingId }: { bookingId: string }) {
    return `/bookings/${bookingId}/merchant-refund`;
  },
  listingBookings({ listingId }: { listingId: string }) {
    return `/listings/${listingId}/bookings`;
  },
  cancelledListingBookings({ listingId }: { listingId: string }) {
    return `/listings/${listingId}/bookings/cancelled`;
  },
  getListingBookingSummary({ listingId }: { listingId: string }) {
    return `/listings/${listingId}/booking-summary`;
  },
  getListingParticipantsCsv({ listingId }: { listingId: string }) {
    return `listings/${listingId}/participants/csv`;
  },
  getMerchantBookings({ merchantId }: { merchantId: string }) {
    return `merchants/${merchantId}/bookings`;
  },
};

export const bookings = ({
  axiosInstance,
}: {
  axiosInstance: AxiosInstance;
}) => {
  return {
    getBookingById: async ({
      bookingId,
    }: {
      bookingId: string;
    }): Promise<Booking> => {
      const { data } = await axiosInstance.get(
        endpoints.bookings({ bookingId }),
        {
          headers: {
            apiKey: process.env.REACT_APP_S2S_API_KEY,
          },
        }
      );
      return data;
    },
    refundBooking: async (
      bookingId: string,
      refundBookingDto: RefundBookingDto
    ): Promise<void> => {
      const { data } = await axiosInstance.post(
        endpoints.bookingsRefund({ bookingId }),
        refundBookingDto
      );
      return data;
    },
    getAllListingBookings: async ({
      listingId,
    }: {
      listingId: string;
    }): Promise<Booking[]> => {
      const { data } = await axiosInstance.get(
        endpoints.listingBookings({ listingId })
      );
      return data;
    },
    getAllCancelledListingBookings: async ({
      listingId,
    }: {
      listingId: string;
    }): Promise<Booking[]> => {
      const { data } = await axiosInstance.get(
        endpoints.cancelledListingBookings({ listingId })
      );
      return data;
    },
    getListingBookingSummary: async ({
      listingId,
    }: {
      listingId: string;
    }): Promise<EventBookingSummary> => {
      const { data } = await axiosInstance.get(
        endpoints.getListingBookingSummary({ listingId })
      );
      return data;
    },
    getListingParticipantsCsv: async ({
      listingId,
    }: {
      listingId: string;
      // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    }): Promise<any> => {
      return await axiosInstance.get(
        endpoints.getListingParticipantsCsv({ listingId })
      );
    },
    getMerchantBookings: async ({
      merchantId,
      startDate,
      productIds,
      page,
      limit,
    }: {
      merchantId: string;
      startDate?: string;
      productIds?: string[];
      page?: number;
      limit?: number;
    }): Promise<Pagination<Booking>> => {
      const { data } = await axiosInstance.get(
        endpoints.getMerchantBookings({ merchantId }),
        {
          params: {
            startDate,
            productIds:
              productIds && productIds?.length > 0
                ? productIds.join(',')
                : undefined,
            page,
            limit,
          },
        }
      );
      return data;
    },
  };
};

import { Box, OutlinedInput, Select, SxProps } from '@mui/material';
import { MenuItem, useTheme } from '@silverstein-properties/inspirelabs-ui';
import { Autocomplete, OptionType } from './Autocomplete';

export const EVENTS_FILTERS = {
  ALL: 'all',
  BOOKED: 'booked',
  CUSTOMERS: 'customers',
} as const;

const eventsFilters = [
  { title: 'All Events', value: EVENTS_FILTERS.ALL },
  { title: 'Booked Events', value: EVENTS_FILTERS.BOOKED },
  { title: 'Customers', value: EVENTS_FILTERS.CUSTOMERS },
];

export const CalendarFilter = ({
  products = [],
  onChangeSelectedProducts,
  onChangeSelectedFilter,
  selectedFilter = EVENTS_FILTERS.ALL,
  sx,
}: {
  products?: OptionType[];
  onChangeSelectedProducts: (values: string[]) => void;
  onChangeSelectedFilter: (value: string) => void;
  selectedFilter?: string;
  sx?: SxProps;
}) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: 0,
        width: '100%',
        ...sx,
      }}
    >
      <Autocomplete items={products} onChange={onChangeSelectedProducts} />

      <Select
        value={selectedFilter}
        onChange={e => onChangeSelectedFilter(e.target.value)}
        sx={{
          maxWidth: '180px',
          borderTopRightRadius: `${theme.constants.BORDER_RADIUS.large}px`,
          borderBottomRightRadius: `${theme.constants.BORDER_RADIUS.large}px`,
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
          '&.MuiOutlinedInput-root': {
            '& .MuiOutlinedInput-input': {
              padding: `${theme.spacing(1.25)} ${theme.spacers.m}`,
              fontWeight: theme.typography.fontWeightMedium,
            },
            '& .MuiOutlinedInput-notchedOutline': {
              borderWidth: 2,
              borderLeftWidth: 0,
              borderColor: theme.palette.primary.main,
            },
          },
        }}
        input={<OutlinedInput fullWidth />}
      >
        {eventsFilters.map(filter => (
          <MenuItem key={filter.value} value={filter.value}>
            {filter.title}
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
};

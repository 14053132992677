import { Booking } from '@/types';
import {
  createInitialsFromName,
  formatDate,
  formatPricing,
  formatTime,
} from '@/utils';
import {
  Avatar,
  Box,
  Link,
  Typography,
  useTheme,
} from '@silverstein-properties/inspirelabs-ui';
import { createColumnHelper } from '@tanstack/react-table';

export const getTableColumns = () => {
  const columnHelper = createColumnHelper<Booking>();
  const theme = useTheme();

  return [
    columnHelper.accessor(row => row, {
      id: 'fullName',
      header: () => (
        <Typography variant="labelMedium" color="primary.medium">
          Name
        </Typography>
      ),
      cell: cellContext => {
        const { consumer } = cellContext.row.original;
        return (
          <Box
            sx={{ display: 'flex', alignItems: 'center', gap: theme.spacers.m }}
          >
            <Avatar
              src={consumer?.image_url}
              alt={`${consumer?.firstName} ${consumer?.lastName}`}
              sx={{
                ...theme.typography.subtitle1,
                color: theme.palette.primary.main,
                backgroundColor: theme.palette.secondary.light,
                height: 44,
                width: 44,
              }}
            >
              {createInitialsFromName(
                `${consumer?.firstName} ${consumer?.lastName}`
              )}
            </Avatar>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography variant="labelLarge">
                {consumer.firstName} {consumer.lastName}
              </Typography>
            </Box>
          </Box>
        );
      },
      minSize: 300,
    }),

    columnHelper.accessor(row => row, {
      id: 'Service',
      header: () => (
        <Typography variant="labelMedium" color="primary.medium">
          Service
        </Typography>
      ),
      cell: cellContext => {
        const { listingSnapshot } = cellContext.row.original;
        return (
          <Box>
            <Link
              href={`/experiences/${listingSnapshot?.productSnapshot?.id}#basicInfo`}
              variant="body2"
              sx={{
                ...theme.typography.body3,
              }}
            >
              {listingSnapshot?.productSnapshot?.title}
            </Link>
          </Box>
        );
      },
      size: 200,
    }),

    columnHelper.accessor(row => row, {
      id: 'location',
      header: () => (
        <Typography variant="labelMedium" color="primary.medium">
          Location
        </Typography>
      ),
      cell: cellContext => {
        const { listingSnapshot, location: bookingLocation } =
          cellContext.row.original;
        const productLocation = listingSnapshot?.productSnapshot?.location;
        const location = bookingLocation || productLocation;

        return (
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {typeof location?.address === 'string' ? (
              <Typography variant="body3">{location?.address}</Typography>
            ) : (
              <>
                <Typography variant="body3">{location?.name}</Typography>
                <Typography variant="body3" color="textSecondary">
                  {location?.address?.apartment}
                </Typography>
                <Typography variant="body3" color="textSecondary">
                  {location?.address?.street}
                </Typography>
              </>
            )}
          </Box>
        );
      },
      size: 200,
    }),

    columnHelper.accessor(row => row.pricing, {
      id: 'basePrice.totalAmount',
      header: () => (
        <Typography variant="labelMedium" color="primary.medium">
          Price
        </Typography>
      ),
      cell: cellContext => {
        const price = cellContext.getValue();
        let priceLabel = '---';
        let isFree = false;
        if (price) {
          if (!price?.total || price?.total === 0) {
            isFree = true;
            priceLabel = 'FREE';
          } else {
            priceLabel = formatPricing(price?.total || 0, 2);
          }
        }

        return (
          <Typography
            variant="body3"
            color={
              isFree ? theme.palette.success.main : theme.palette.text.secondary
            }
          >
            {priceLabel}
          </Typography>
        );
      },
      size: 1,
    }),

    columnHelper.accessor(row => row.listingSnapshot?.startsAt, {
      id: 'dateTime',
      header: () => (
        <Typography variant="labelMedium" color="primary.medium">
          Date & Time
        </Typography>
      ),
      cell: cellContext =>
        cellContext.getValue() ? (
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography variant="body3">
              {formatDate(cellContext.getValue()!)}
            </Typography>
            <Typography variant="body3" color="textSecondary">
              {formatTime(cellContext.getValue()!)}
            </Typography>
          </Box>
        ) : null,
      size: 100,
    }),

    // columnHelper.accessor('id', {
    //   id: 'view',
    //   header: () => (
    //     <Typography variant="labelMedium" color="primary.medium"></Typography>
    //   ),
    //   cell: info =>
    //     info.row.depth === 0 && onButtonClick ? (
    //       <Button
    //         variant="outlined"
    //         size="small"
    //         onClick={() => onButtonClick('view', info.getValue())}
    //         sx={{ float: 'right', width: 'auto' }}
    //       >
    //         Details
    //       </Button>
    //     ) : null,
    //   enableSorting: false,
    //   size: 1,
    // }),
  ];
};

import {
  InvoicePaymentStatus,
  InvoiceStatus,
  MembersDataTable,
  MembershipPlanPurchaseInvoice,
  MembershipPlanPurchaseQueryResponse,
  PaymentIntentStatus,
  PlanConsumerStatus,
} from '@/types';
import { capitalize } from 'lodash';
import { formatDateShortForm } from './dateUtils';
import { Typography } from '@silverstein-properties/inspirelabs-ui';
import { isFuture } from 'date-fns';
import {
  convertPriceAndBillingToString,
  determineMembersDisplayStatus,
} from './membershipUtils';

export const displayInvoicePaymentValue = (
  payment: MembershipPlanPurchaseInvoice
) => {
  if (
    payment.paymentIntent?.latestCharge &&
    payment.paymentIntent.latestCharge.amountRefunded > 0
  ) {
    return `$-${payment.paymentIntent.latestCharge.amountRefunded}`;
  } else if (payment.paymentIntent?.latestCharge?.disputed) {
    return `$-${payment.paymentIntent.latestCharge.amount}`;
  } else if (payment.status === InvoiceStatus.PAID) {
    return `$${payment.amountPaid}`; // Convert cents -> dollars
  } else {
    return `$${payment.amountRemaining}`;
  }
};

export const formatCardInfo = (brand: string, last4: string) => {
  return `${capitalize(brand?.toLowerCase())}...${last4}`;
};

export const displayInvoicePaymentMethod = (
  payment: MembershipPlanPurchaseInvoice
) => {
  if (payment.status === InvoiceStatus.PAID) {
    // If paymentIntent is null, but the invoice is Paid then it means is a trial subscription
    if (!payment.paymentIntent) {
      return '';
    }
    return formatCardInfo(
      payment.paymentIntent?.paymentMethod?.card?.brand,
      payment.paymentIntent?.paymentMethod?.card?.last4
    ); // To display "Visa... 4242"
  } else if (payment.paymentIntent?.lastPaymentError) {
    return formatCardInfo(
      payment.paymentIntent.lastPaymentError?.paymentMethod?.card?.brand?.toLowerCase(),
      payment.paymentIntent.lastPaymentError?.paymentMethod?.card?.last4
    );
  } else {
    return '';
  }
};

export const displayInvoicePaymentColor = (status: InvoicePaymentStatus) => {
  switch (status) {
    case InvoicePaymentStatus.SETTLED:
      return 'success.main';
    case InvoicePaymentStatus.PROCESSING:
    case InvoicePaymentStatus.UPCOMING:
    case InvoicePaymentStatus.REFUNDED:
    case InvoicePaymentStatus.PARTIAL_REFUNDED:
      return 'primary.medium';
    case InvoicePaymentStatus.DECLINED:
    case InvoicePaymentStatus.CANCELED:
    case InvoicePaymentStatus.DISPUTED:
      return 'error.main';
  }
};

export const invoicePaymentStatus = (
  payment: MembershipPlanPurchaseInvoice
) => {
  if (payment.status === InvoiceStatus.DRAFT) {
    if (isFuture(payment.created)) {
      return InvoicePaymentStatus.UPCOMING;
    }
    return InvoicePaymentStatus.PROCESSING;
  }
  // If paymentIntent is null, but the invoice is Paid then it means is a trial subscription
  if (!payment?.paymentIntent && payment.status === InvoiceStatus.PAID) {
    return InvoicePaymentStatus.SETTLED;
  }
  if (
    payment.paymentIntent?.latestCharge &&
    payment.paymentIntent.latestCharge.amountRefunded > 0
  ) {
    return payment?.paymentIntent?.latestCharge?.refunded
      ? InvoicePaymentStatus.REFUNDED
      : InvoicePaymentStatus.PARTIAL_REFUNDED;
  }
  if (payment.paymentIntent?.latestCharge?.disputed) {
    return InvoicePaymentStatus.DISPUTED;
  }
  switch (payment?.paymentIntent?.status) {
    case PaymentIntentStatus.REQUIRES_PAYMENT_METHOD:
    case PaymentIntentStatus.REQUIRES_CONFIRMATION:
    case PaymentIntentStatus.REQUIRES_ACTION:
    case PaymentIntentStatus.REQUIRES_CAPTURE:
      return InvoicePaymentStatus.DECLINED;
    case PaymentIntentStatus.PROCESSING:
      return InvoicePaymentStatus.PROCESSING;
    case PaymentIntentStatus.CANCELED:
      return InvoicePaymentStatus.CANCELED;
    case PaymentIntentStatus.SUCCEEDED:
      return InvoicePaymentStatus.SETTLED;
  }
};

export const displayInvoicePaymentStatus = (
  payment: MembershipPlanPurchaseInvoice
) => {
  if (payment.status === InvoiceStatus.DRAFT) {
    if (isFuture(payment.created)) {
      return InvoicePaymentStatus.UPCOMING;
    }
    return InvoicePaymentStatus.PROCESSING;
  }
  if (payment.nextPaymentAttempt) {
    return (
      <Typography variant="body3" color="primary.medium">
        Processing <br />
        Next retry attempt <br />
        on {formatDateShortForm(payment.nextPaymentAttempt, 'MM/dd/yyyy')}
      </Typography>
    );
  }
  // If paymentIntent is null, but the invoice is Paid then it means is a trial subscription
  if (!payment?.paymentIntent && payment.status === InvoiceStatus.PAID) {
    return InvoicePaymentStatus.SETTLED;
  }
  if (
    payment.paymentIntent?.latestCharge &&
    payment.paymentIntent.latestCharge.amountRefunded > 0
  ) {
    return payment?.paymentIntent?.latestCharge?.refunded
      ? InvoicePaymentStatus.REFUNDED
      : InvoicePaymentStatus.PARTIAL_REFUNDED;
  }
  if (payment.paymentIntent?.latestCharge?.disputed) {
    return InvoicePaymentStatus.DISPUTED;
  }
  switch (payment?.paymentIntent?.status) {
    case PaymentIntentStatus.REQUIRES_PAYMENT_METHOD:
    case PaymentIntentStatus.REQUIRES_CONFIRMATION:
    case PaymentIntentStatus.REQUIRES_ACTION:
    case PaymentIntentStatus.REQUIRES_CAPTURE:
      return InvoicePaymentStatus.DECLINED;
    case PaymentIntentStatus.PROCESSING:
      return InvoicePaymentStatus.PROCESSING;
    case PaymentIntentStatus.CANCELED:
      return InvoicePaymentStatus.CANCELED;
    case PaymentIntentStatus.SUCCEEDED:
      return InvoicePaymentStatus.SETTLED;
  }
};

// Transform the data into the expected format from the table.
export const transformPlanPurchases = (
  membershipPlanPurchases: MembershipPlanPurchaseQueryResponse
): MembersDataTable[] => {
  return (
    membershipPlanPurchases?.purchases?.map(purchase => {
      const membersDisplayStatus = determineMembersDisplayStatus(purchase);
      return {
        id: purchase.id,
        name: `${purchase.firstName} ${purchase.lastName}`,
        plan: purchase.membershipPlanSnapshot.name,
        totalMembers:
          purchase.planConsumers?.filter(
            consumer => consumer.status === PlanConsumerStatus.ACTIVE
          ).length || 0,
        payment: convertPriceAndBillingToString(
          purchase.membershipPlanSnapshot.totalAmount || 0,
          purchase.membershipPlanSnapshot.billingCycleType
        ),
        status: membersDisplayStatus.status,
        statusColor: purchase.pauseCollection
          ? 'success.hold'
          : membersDisplayStatus.color || 'primary.medium',
        activeUntil: membersDisplayStatus.expiration || '',
        isFrozen: purchase.pauseCollection,
        members: purchase.planConsumers
          .filter(
            consumer =>
              consumer.consumerId !== purchase?.primaryConsumerId &&
              consumer.status === PlanConsumerStatus.ACTIVE
          )
          .map(planConsumer => ({
            id: planConsumer.consumerId,
            name: `${planConsumer.consumer?.firstName ?? '---'} ${
              planConsumer.consumer?.lastName ?? ''
            }`,
            plan: purchase.membershipPlanSnapshot.name,
          })),
      };
    }) || []
  );
};

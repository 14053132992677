import {
  Box,
  InputField,
  Stack,
  Typography,
} from '@silverstein-properties/inspirelabs-ui';
import { FormControl, FormHelperText, MenuItem, Select } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import {
  BookingMethod,
  Experience,
  Location,
  LocationEnvironmentTypes,
  ProductTypes,
} from '@/types';
import { LabelField } from '@/components';
import {
  renderInputFieldHelperText,
  isAddressEditAvailable,
  isDescriptionEditAvailable,
  getServiceTypeFromOptions,
} from '@/utils';
import { SERVICE_TYPE_CHOICES, TEXT_AREA_LIMIT_LONG } from '@/constants';
import { LocationSectionEditInput } from '@/classes/LocationSectionEditInput';
import { useEffect } from 'react';

const resolver = classValidatorResolver(LocationSectionEditInput);

export type LocationSectionEditPropsType = {
  experience: Experience;
  location?: Location | null;
  onFormSubmit: (data: LocationSectionEditInput) => void;
};

export const LocationSectionEdit = ({
  experience,
  location,
  onFormSubmit,
}: LocationSectionEditPropsType) => {
  const isNotAdvert = experience.type !== ProductTypes.ADVERT;

  const {
    watch,
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    resetField,
  } = useForm<LocationSectionEditInput>({
    resolver,
    mode: 'onTouched',
    defaultValues: {
      address: isNotAdvert
        ? {
            street: location?.address?.street || '',
            city: location?.address?.city || '',
            state: location?.address?.state || '',
            apartment: location?.address?.apartment || '',
            zipCode: location?.address?.zipCode || '',
            // default in BE that country is US
          }
        : undefined,
      environmentType:
        location?.environmentType ||
        (experience.bookingMethods?.includes(BookingMethod.DELIVERY)
          ? LocationEnvironmentTypes.INDOOR_CUSTOMER_LOCATION
          : LocationEnvironmentTypes.NONE),
      ...(!experience.bookingMethods?.includes(BookingMethod.DELIVERY) && {
        description: location?.info || location?.description || '',
      }),
      isExperienceAdvert: !isNotAdvert,
    },
  });

  const environmentType = watch('environmentType');

  // For request with booking request, we want to only show the address
  // fields only if the location isn't at the customer's location

  const canAddressBeEdited = isAddressEditAvailable(
    experience.bookingMethods,
    environmentType
  );

  const isDescriptionAvailable = isDescriptionEditAvailable(
    experience.bookingMethods,
    environmentType
  );

  const onSubmit = (data: LocationSectionEditInput) => {
    onFormSubmit(data);
  };

  useEffect(() => {
    if (!canAddressBeEdited) {
      setValue('address', undefined, {
        shouldValidate: false,
        shouldDirty: false,
      });
    }
  }, [canAddressBeEdited]);

  useEffect(() => {
    resetField('address');
    setValue(
      'shouldValidateAddress',
      isAddressEditAvailable(experience.bookingMethods, environmentType)
    );
  }, [environmentType]);

  return (
    <Stack spacing={4}>
      <Typography variant="h5">Location</Typography>
      <form id="locationForm" onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={4} width="100%">
          {isNotAdvert && (
            <LabelField
              label="Where is the experience taking place?"
              value={
                <Box mt={2}>
                  <Controller
                    name="environmentType"
                    control={control}
                    render={({ field }) => (
                      <FormControl fullWidth>
                        <Select
                          {...field}
                          data-testid="locationType"
                          fullWidth
                          disabled={experience.bookingMethods?.includes(
                            BookingMethod.DELIVERY
                          )}
                          displayEmpty={true}
                          error={!!errors.environmentType}
                          renderValue={selected => {
                            if (!selected) {
                              return 'Select location type';
                            }
                            const selectedLocationType =
                              getServiceTypeFromOptions(selected);
                            return selectedLocationType?.label || '';
                          }}
                        >
                          {SERVICE_TYPE_CHOICES.map(choice => (
                            <MenuItem key={choice.id} value={choice.id}>
                              {choice.label}
                            </MenuItem>
                          ))}
                        </Select>
                        <FormHelperText error={!!errors.environmentType}>
                          {errors.environmentType?.message}
                        </FormHelperText>
                      </FormControl>
                    )}
                  />
                </Box>
              }
            />
          )}
          {environmentType && canAddressBeEdited && isNotAdvert ? (
            <>
              <LabelField
                label="Edit address"
                value={
                  <>
                    <Stack direction="row" my={2}>
                      <Controller
                        control={control}
                        name="address.street"
                        render={({ field }) => (
                          <Box flex={2}>
                            <InputField
                              {...field}
                              data-testid="addressStreet"
                              error={!!errors.address?.street}
                              label="Address"
                              helperText={errors.address?.street?.message}
                            />
                          </Box>
                        )}
                      />
                      <Controller
                        control={control}
                        name="address.apartment"
                        render={({ field }) => (
                          <Box flex={1}>
                            <InputField
                              {...field}
                              data-testid="addressApt"
                              error={!!errors.address?.apartment}
                              label="Apt./Fl."
                              helperText={errors.address?.apartment?.message}
                            />
                          </Box>
                        )}
                      />
                    </Stack>
                    <Stack direction="row">
                      <Controller
                        control={control}
                        name="address.city"
                        render={({ field }) => (
                          <Box flex={2}>
                            <InputField
                              {...field}
                              data-testid="addressCity"
                              error={!!errors.address?.city}
                              label="City"
                              helperText={errors.address?.city?.message}
                            />
                          </Box>
                        )}
                      />
                      <Controller
                        control={control}
                        name="address.state"
                        render={({ field }) => (
                          <Box flex={1}>
                            <InputField
                              {...field}
                              data-testid="addressState"
                              label="State"
                              error={!!errors.address?.state}
                              helperText={errors.address?.state?.message}
                            />
                          </Box>
                        )}
                      />
                      <Controller
                        control={control}
                        name="address.zipCode"
                        render={({ field }) => (
                          <Box flex={1}>
                            <InputField
                              {...field}
                              data-testid="addressZip"
                              label="Zipcode"
                              error={!!errors.address?.zipCode}
                              helperText={errors.address?.zipCode?.message}
                            />
                          </Box>
                        )}
                      />
                    </Stack>
                  </>
                }
              />
            </>
          ) : null}
          {environmentType && isDescriptionAvailable && isNotAdvert ? (
            <LabelField
              label="Describe the location"
              value={
                <Box mt={2}>
                  <Controller
                    control={control}
                    name="description"
                    render={({ field }) => (
                      <InputField
                        {...field}
                        data-testid="locationDescription"
                        label="Add description"
                        multiline
                        minRows={6}
                        error={!!errors.description}
                        helperText={renderInputFieldHelperText(
                          watch('description')?.length,
                          TEXT_AREA_LIMIT_LONG,
                          errors.description?.message
                        )}
                      />
                    )}
                  />
                </Box>
              }
            />
          ) : null}
        </Stack>
      </form>
    </Stack>
  );
};

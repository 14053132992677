export class Address {
  id?: string;
  street: string;
  apartment?: string;
  city: string;
  state: string;
  zipCode: string;
  country: string;
}

export enum LocationTypes {
  IN_PERSON = 1,
  ONLINE,
}

export enum ValidLocationEnvironmentTypes {
  INDOOR = 1,
  INDOOR_CUSTOMER_LOCATION,
  OUTDOOR,
  OUTDOOR_CUSTOMER_LOCATION,
  COMMON_AREA,
  OUTDOOR_TOUR,
  GENERIC_CUSTOMER_LOCATION,
  GENERIC_PROVIDER_LOCATION,
}

export enum LocationEnvironmentTypes {
  NONE = 0,
  INDOOR,
  INDOOR_CUSTOMER_LOCATION,
  OUTDOOR,
  OUTDOOR_CUSTOMER_LOCATION,
  COMMON_AREA,
  OUTDOOR_TOUR,
  GENERIC_CUSTOMER_LOCATION,
  GENERIC_PROVIDER_LOCATION,
}

export interface Location {
  id?: number;
  name?: string;
  info?: string;
  description?: string;
  type?: LocationTypes;
  environmentType?: LocationEnvironmentTypes;
  addressId?: string;
  address?: Address;
  url?: string;
}

export interface EventLocation {
  id?: number;
  name?: string;
  info: string;
  street?: string;
  apartment?: string;
  city?: string;
  state?: string;
  zipCode: string;
  country?: string;
  address: Address;
  environmentType?: LocationEnvironmentTypes;
  type?: LocationTypes;
  url?: string;
  description?: string;
}

export type PartialLocationObject = Partial<Location>;

export interface Building {
  id: number;
  name: string;
  street: string;
  city: string;
  state: string;
  zip: string;
  buildingCode?: string;
}

export type BuildingSelect = Pick<Building, 'id' | 'name'>;

import { styled } from '@silverstein-properties/inspirelabs-ui';

export const StyledCalendar = styled('div')(
  ({ theme }) => `
  position: relative;
  
  .fc-dayGridMonth-button button {
    border-radius: 22px;
  }

  .fc .fc-daygrid-day-frame {
    min-height: 100%;
    position: relative;
    height: 100px;
}

  .fc-button {
    --fc-button-bg-color: ${theme.palette.common.white};
    --fc-button-active-bg-color: ${theme.palette.common.white};
    --fc-button-text-color: ${theme.palette.primary.main};
    --fc-button-border-color: ${theme.palette.grey[400]};
    border-radius: 22px;
    padding: 12px 18px;
    box-shadow: none;
    --fc-small-font-size: 14px;
  }

  .fc td,
  .fc th {
    border-style: 0px;
    box-shadow: none;
  }

  .fc-button:hover {
    --fc-button-hover-bg-color: ${theme.palette.common.white};
    --fc-button-text-color: ${theme.palette.primary.main};
    --fc-button-hover-border-color: ${theme.palette.grey[400]};
    border-radius: 22px;
    padding: 12px 18px;
    box-shadow: none;
    --fc-small-font-size: 14px;
  }

  .fc-button-active {
    --fc-button-text-color: ${theme.palette.primary.main};
    --fc-button-active-bg-color: ${theme.palette.common.white};
    --fc-button-active-border-color: ${theme.palette.primary.main};
    border-radius: 22px;
    padding: 12px 18px;
  }

  .fc .fc-button {
    border-radius: 22px;
    margin-right: 10px;
    padding: 12px 16px;
    box-shadow: none;
    font-weight: 500;
  }

  /* cancel line */

  .cancelled-listing {
    text-decoration: line-through;
    color: #000; // Customize the color for cancelled listings
  }

  .cancelled-listing:hover {
    text-decoration: line-through;
  }

  .fc .fc-event-title {
    text-align: right;
  }

  /* cursor pointer when hovering over event */
  .fc-event {
    cursor: pointer;
    padding: 0px 4px;
  }

  /* previous and next buttons */

  .fc-next-button {
    --fc-button-border-color: ${theme.palette.common.white};
    --fc-button-bg-color: ${theme.palette.common.white};
    --fc-button-active-bg-color: ${theme.palette.common.white};
    --fc-button-active-border-color: ${theme.palette.common.white};
  }

  .fc-next-button:hover {
    --fc-button-hover-border-color: ${theme.palette.common.white};
    --fc-button-hover-bg-color: ${theme.palette.common.white};
  }

  .fc .fc-next-button {
    width: 24px;
    padding: 0px;
  }

  .fc-prev-button {
    --fc-button-border-color: ${theme.palette.common.white};
    --fc-button-bg-color: ${theme.palette.common.white};
    --fc-button-active-bg-color: ${theme.palette.common.white};
    --fc-button-active-border-color: ${theme.palette.common.white};
  }

  .fc-prev-button:hover {
    --fc-button-hover-border-color: ${theme.palette.common.white};
    --fc-button-hover-bg-color: ${theme.palette.common.white};
  }

  .fc .fc-prev-button {
    width: 24px;
    padding: 0px;
  }

  .fc-col-header-cell {
    --fc-border-left: none;
    --fc-border-right: none;
  }

  .fc-scroller {
    border-color: ${theme.palette.common.white};
  }

  .fc .fc-toolbar-title {
    letter-spacing: -0.2px;
  }

  .fc-toolbar .fc-toolbar-title {
    font-size: 21px;
    letter-spacing: -0.2px;
  }

  .fc .fc-toolbar .fc-toolbar-title {
    font-size: 21px;
    letter-spacing: -0.2px;
  }

  #fc-dom-86 {
    letter-spacing: -0.2px;
  }

  .fc-header-title {
    letter-spacing: -0.2px;
  }

  .fc .fc-col-header-cell-cushion {
    font-weight: 300;
    padding-bottom: 8px;
    color: ${theme.palette.primary.medium};
  }

  .fc .fc-toolbar {
    display: flex;
    justify-content: space-between; 
  }

  .fc .fc-toolbar > *:first-child,
  .fc .fc-toolbar > *:nth-child(2) {
    flex: 0 0 auto; /* Keeps the first two chunks fixed */
    margin-right: 8px;
  }

  .fc .fc-toolbar > *:nth-child(3) {
    margin-left: auto; /* Pushes the third chunk to the right */
  }

  .fc .fc-daygrid-day.fc-day-today {
    background-color: ${theme.palette.secondary.faint};
  }

  .fc .fc-day-today {
    background-color: ${theme.palette.secondary.faint};
  }

  .fc .fc-daygrid-day.fc-day-today {
    background-color: ${theme.palette.secondary.faint};
  }


  /* Add hover effect to calendar dates */
  .fc .fc-daygrid-day:hover {
    background-color: ${theme.palette.secondary.faint}; 
    cursor: pointer;
  }

  /* Ensure the hover effect doesn't override the today highlight */
  .fc .fc-daygrid-day.fc-day-today:hover {
    background-color: ${theme.palette.secondary.faint};
  }

  .fc-col-header-cell.fc-day-sat, .fc-col-header-cell.fc-day-sun {
    background-color: transparent;
  }

  .fc-theme-standard .fc-scrollgrid {
    border: 0px;
  }

  .fc-theme-standard th {
    border: 0px;
  }

  .fc-list td {
    border: 0px;
  }

  .fc-event {
    border: 0px solid ${theme.palette.grey[400]};
  }

  .fc .fc-list-event-graphic {
    display: none;
  }

  .fc-list-event-dot {
    display: none;
  }

  .fc-scroller {
    height: auto !important;
  }

  .fc-list-table .fc-list-event:hover td {
    background-color: ${theme.palette.common.white};
  }

  .fc .fc-scrollgrid-section-liquid > td {
    border-right: 0px;
    border-bottom: 0px;
  }

  .fc .fc-scrollgrid table {
    border: 0px;
  }

  

  .fc table {
    border: 0px solid ${theme.palette.grey[400]};
  }

  .fc-button-group > button {
    border-radius: 30px;
  }

  .fc-direction-ltr .fc-button-group > .fc-button:not(:first-of-type) {
    border-radius: 30px;
    box-shadow: none;
  }

  .fc-direction-ltr .fc-button-group > .fc-button:not(:last-child) {
    border-radius: 30px;
    box-shadow: none;
  }

  .fc-listMonth-button, .fc-dayGridMonth-button, .fc-dayGridWeek-button, .fc-timeGridDay-button {
    border: 2px solid ${theme.palette.grey[400]};
  }

  /* Gray color for weekends and disabled days in the month view */
  .fc-day-sat, .fc-day-sun, .fc-day-disabled {
    background-color: ${theme.palette.grey[100]}; 
  }

  .fc .fc-button-primary:not(:disabled).fc-button-active:focus,
  .fc .fc-button-primary:not(:disabled):active:focus {
    box-shadow: none;
  }

  .fc .fc-daygrid-event {
    font-weight: 300;
  }

  .recurringEvent {
    display: flex;
    justify-content: space-between;
    background-color: ${theme.palette.secondary.main};
    border: 1px solid #ccc;
  }
  .recurringLabel {
    display: flex;
    justify-content: space-between;
    font-style: italic;
    background-color: ${theme.palette.secondary.main};
    color: #666; 
  }

  .timeListBlock {
    display: inline-flex;
    justify-content: space-between;
  }


  .cellTime { 
    display: flex;
    justify-content: space-between;
  } 

  .eventTimeFormat {
    text-align: left;
    float:left;
  }
  
  .cellPrice {
    text-align:right;
    float:right;
  }
/* list table */

.fc-list-day-side-text {
  display: none;
}

.fc .fc-list-table {
  border: 0px;
}

.fc .fc-list-day-cushion {
  background-color: ${theme.palette.common.white};
  border: 0px;
}

.fc .fc-list-table .fc-list-day-cushion .fc-list-day-frame {
  border: 2px solid ${theme.palette.grey[400]};
  border-radius: 50px;
}


.fc-theme-standard .fc-list {
  border: 0px;
  }

  /* list events */

  .listTitle {
    width: 300px;
    font-weight: 500;
    font-size: 18px;
  }

  .listGuest {
    margin-left: 10%;
  }
  
  .listDate {
    float:right;
    text-align: right;
  }

  .fc-event {
    font-weight: 300;
  }

  .fc .fc-toolbar {
    justify-content: space-between;
  }

  .fc .fc-event:hover {
    background-color: #000;
  }

  .fc-event-time {
    font-weight: 300;
  }

  .fc .fc-event-time {
    font-weight: 300;
  }

  .fc .fc-daygrid-day-events{
    text-align: right;
  }

  @media only screen and (max-width: 1200px) {
    .fc-toolbar-title {
      height: 28px;
      width: 28vw;

      font-family: 'Lexend';
      font-style: normal;
      font-weight: 400;
      line-height: 135%;

      text-align: left;
      letter-spacing: -0.2px;
    }

    .fc .fc-toolbar-title {
      height: 28px;
      width: 28vw;
      font-weight: 400;
      font-size: 21px;
      line-height: 135%;
      /* or 28px */

      text-align: left;
      letter-spacing: -0.2px;
    }
  }

  @media (min-width:900px) and (max-width:945px) {
    .fc .fc-toolbar-title {
      height: 28px;
      width: 26vw;
      font-weight: 400;
      font-size: 21px;
      line-height: 135%;
      /* or 28px */

      text-align: left;
      letter-spacing: -0.2px;
    }
  }
  }

  @media only screen and (max-width: 901px) {
    .fc .fc-toolbar-title {
      height: 28px;
      width: 50vw;
      font-weight: 400;
      font-size: 21px;
      line-height: 135%;
      /* or 28px */

      text-align: left;
      letter-spacing: -0.2px;
    }
  }

  @media only screen and (max-width: 720px) {
    .fc .fc-toolbar-title {
      height: 28px;
      width: 37vw;
      font-weight: 400;
      font-size: 21px;
      line-height: 135%;
      /* or 28px */

      text-align: left;
      letter-spacing: -0.2px;
    }
  }

  @media only screen and (max-width: 430px) {
    .fc-toolbar-title {
      margin-left: 3px;
      margin-right: 3px;
    }

    .fc .fc-toolbar-title {
      margin-left: 3px;
      margin-right: 3px;
    }

    .fc-dayGridMonth-button {
      border-radius: 30px;
      margin: 0px;
    }

    .fc .fc-dayGridMonth-button {
      border-radius: 30px;
      margin: 0px;
    }

    .fc-dayGridWeek-button {
      border-radius: 30px;
    }

    .fc .fc-dayGridWeek-button {
      border-radius: 30px;
    }

    .fc-timeGridDay-button {
      border-radius: 30px;
    }

    .fc .fc-timeGridDay-button {
      border-radius: 30px;
    }

    .fc-listMonth-button {
      border-radius: 30px;
    }

    .fc .fc-listMonth-button {
      border-radius: 30px;
    }

    .fc-button {
      margin: 2px;
    }

    .fc .fc-button {
      margin: 2px;
    }
  }
`
);

import {
  ClickAwayListener,
  MenuItem,
  OutlinedInput,
  Popper,
  Box,
} from '@mui/material';
import {
  ChevronDownIcon,
  Typography,
  useTheme,
  Checkbox,
} from '@silverstein-properties/inspirelabs-ui';
import { useEffect, useMemo, useRef, useState } from 'react';

const SelectItem = ({
  value,
  title,
  checked,
  indeterminate,
  onClick,
}: {
  value: string;
  title: string;
  checked?: boolean;
  indeterminate?: boolean;
  onClick: (key: string) => void;
}) => {
  const theme = useTheme();
  return (
    <MenuItem
      onClick={() => onClick(value)}
      value={value}
      sx={{
        p: 0,
        py: theme.spacing(1.25),
        m: 0,
        gap: theme.spacing(1.5),
        '&:hover': { backgroundColor: 'transparent' },
      }}
    >
      <Checkbox
        sx={{ p: 0, m: 0 }}
        indeterminate={indeterminate}
        checked={Boolean(checked)}
      />
      <Typography variant="body2">{title}</Typography>
    </MenuItem>
  );
};

export type OptionType = {
  title: string;
  value: string;
  checked?: boolean;
};

const ITEM_HEIGHT = 44;
const ITEM_PADDING_TOP = 8;
const PRODUCT_IDS_SESSION_STORAGE_KEY = 'selectedProductIds';

export const Autocomplete = ({
  items,
  onChange,
}: {
  items: OptionType[];
  onChange: (values: string[]) => void;
}) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isInputFocused, setIsInputFocused] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const inputRef = useRef<HTMLInputElement | null>(null);

  const [selectedValues, setSelectedValues] = useState<string[]>([]);

  useEffect(() => {
    const storedProductIds = sessionStorage.getItem(
      PRODUCT_IDS_SESSION_STORAGE_KEY
    );
    if (storedProductIds) {
      setSelectedValues(JSON.parse(storedProductIds));
    } else {
      setSelectedValues(items.map(n => n.value));
    }
  }, []);

  const handleChange = (key: string) => {
    inputRef.current?.blur();
    setIsInputFocused(false);
    setSelectedValues(prev => {
      let newArray = [];
      if (key === 'select-all') {
        newArray = prev.length === items.length ? [] : items.map(n => n.value);
      } else {
        const newSet = new Set(prev);
        newSet.has(key) ? newSet.delete(key) : newSet.add(key);
        newArray = Array.from(newSet);
      }
      if (newArray.length > 0) {
        sessionStorage.setItem(
          PRODUCT_IDS_SESSION_STORAGE_KEY,
          JSON.stringify(newArray)
        );
      } else {
        sessionStorage.removeItem(PRODUCT_IDS_SESSION_STORAGE_KEY);
      }

      return newArray;
    });
  };

  useEffect(() => {
    onChange(selectedValues);
  }, [selectedValues]);

  const open = Boolean(anchorEl);
  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    inputRef.current?.focus();
    setAnchorEl(event.currentTarget);
  };

  const handleClickAway = (event: MouseEvent | TouchEvent) => {
    if (inputRef.current && inputRef.current.contains(event.target as Node)) {
      return; // Don't close if clicking inside input
    }
    setAnchorEl(null);
  };

  const getInputValue = () => {
    if (isInputFocused) {
      return searchQuery;
    }
    if (selectedValues.length === items.length && items.length !== 0) {
      return 'All Products';
    }
    if (selectedValues.length === 1) {
      return items.find(item => item.value === selectedValues[0])?.title;
    }
    if (selectedValues.length === 0) {
      return '';
    }
    return `${selectedValues.length} products`;
  };

  const filteredItems = useMemo(
    () =>
      items.filter(item =>
        item.title.toLowerCase().includes(searchQuery.toLowerCase())
      ),
    [items, searchQuery]
  );

  return (
    <>
      <OutlinedInput
        inputRef={inputRef}
        id="demo-positioned-menu"
        fullWidth
        autoComplete="off"
        sx={{
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0,
          borderTopLeftRadius: `${theme.constants.BORDER_RADIUS.large}px`,
          borderBottomLeftRadius: `${theme.constants.BORDER_RADIUS.large}px`,
          width: '100%',
          maxWidth: '358px',
          '& .MuiOutlinedInput-notchedOutline': {
            borderWidth: 2,
            borderColor: theme.palette.primary.main,
          },
        }}
        inputProps={{
          style: {
            fontWeight: isInputFocused
              ? theme.typography.fontWeightRegular
              : theme.typography.fontWeightMedium,
            padding: `${theme.spacing(1.25)} ${theme.spacers.m}`,
          },
        }}
        value={getInputValue()}
        onChange={e => setSearchQuery(e.target.value)}
        onFocus={() => setIsInputFocused(true)}
        onBlur={() => setIsInputFocused(false)}
        onClick={handleOpenMenu}
        endAdornment={
          <ChevronDownIcon
            width={24}
            height={24}
            style={{
              transform: open ? 'rotate(180deg)' : 'rotate(0deg)',
              transition: 'transform 0.2s ease',
            }}
          />
        }
      />
      <Popper
        open={open}
        anchorEl={anchorEl}
        placement="bottom-start"
        modifiers={[
          {
            name: 'preventOverflow',
            options: { boundary: 'window' },
          },
        ]}
        sx={{ zIndex: 100 }}
      >
        <ClickAwayListener onClickAway={handleClickAway}>
          <Box
            sx={{
              minWidth: '358px',
              width: 1,
              position: 'relative',
              backgroundColor: 'white',
              borderRadius: `${theme.constants.BORDER_RADIUS.medium}px`,
              boxShadow: '0px 25px 37px 0px #0000001A',
              py: theme.spacers.s,
              px: theme.spacers.m,
              maxHeight: ITEM_HEIGHT * 8 + ITEM_PADDING_TOP,
              overflow: 'auto',
            }}
          >
            {filteredItems.length > 0 ? (
              <SelectItem
                title="All products"
                value="select-all"
                indeterminate={
                  selectedValues.length > 0 &&
                  selectedValues.length < items.length
                }
                checked={selectedValues.length === items.length}
                onClick={() => handleChange('select-all')}
              />
            ) : (
              <Box sx={{ py: theme.spacers.m }}>
                <Typography variant="body2">No results found</Typography>
              </Box>
            )}
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                pl: theme.spacers.l,
              }}
            >
              {filteredItems.map(item => (
                <SelectItem
                  key={item.value}
                  {...item}
                  onClick={handleChange}
                  checked={selectedValues.includes(item.value)}
                />
              ))}
            </Box>
          </Box>
        </ClickAwayListener>
      </Popper>
    </>
  );
};

import { MonthlyStatement } from 'types';
import { useAuth } from './useAuth';
import { useQuery } from '@tanstack/react-query';
import { ApiClient } from '../api/apiClient';

type usePaymentStatementPropsType = {
  paymentStatementId?: number;
};

export const usePaymentStatement = ({
  paymentStatementId,
}: usePaymentStatementPropsType) => {
  const { data: user } = useAuth();
  const apiClient = ApiClient();

  const getPaymentStatement = async (
    accessToken?: string
  ): Promise<MonthlyStatement | null> => {
    if (accessToken && paymentStatementId) {
      const paymentStatement =
        await apiClient.paymentStatements.getPaymentStatement({
          paymentStatementId: paymentStatementId,
        });
      // Calculate the total number of guests for each experience using event guests
      paymentStatement.experiences.forEach(xp => {
        xp.guests = xp.events.reduce((acc, event) => acc + event.guests, 0);
      });
      return paymentStatement;
    } else {
      return null;
    }
  };

  return useQuery<MonthlyStatement | null>(
    ['paymentStatement', paymentStatementId],
    () => getPaymentStatement(user?.accessToken),
    { enabled: !!user && !!paymentStatementId }
  );
};

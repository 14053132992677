import { useState, useEffect, useMemo } from 'react';
import {
  AddListing,
  Calendar,
  EditListing,
  EditRecurringEvent,
  ViewListing,
  selectionDialogProp,
  CalendarFilter,
  EVENTS_FILTERS,
} from './components';
import {
  Experience,
  CreateListing,
  Listing,
  ExperienceStatus,
  ProductTypes,
  BookingMethod,
} from '@/types';
import {
  useExperiences,
  useFeatureFlags,
  useMerchantListings,
  useLocations,
  useMerchantPlatformFee,
  useMutationListing,
} from '@/hooks';
import { DateClickArg } from '@fullcalendar/interaction';
import {
  formatDateShortForm,
  tomorrow,
  calculatePriceWithPlatformFee,
  isListingModifiable,
  filterAndSortCalendarViewExperience,
  now,
} from '@/utils';
import {
  Container,
  Grid,
  Button,
  useTheme,
  CalendarIcon,
  Typography,
} from '@silverstein-properties/inspirelabs-ui';
import { EmptyState } from '@/components/EmptyState';
import { StyledCalendar } from './components/Calendar/StyledCalendar';
import AlertDialog from '@/components/AlertDialog/AlertDialog';
import { DetailsDrawer } from '@/components';
import { AddListingInput, EditListingInput } from '@/classes';
import { DEFAULT_PLATFORM_FEE } from '@/constants';
import { useRecurringEvents } from '@/hooks/useRecurringEvents';
import { CancelListingInput } from '@/classes/CancelListingInput';
import SelectionDialog from '@/components/SelectionDialog/SelectionDialog';
import { endOfMonth, startOfMonth } from 'date-fns';
import { formatListing } from '@/utils/schedulingUtils';
import { MerchantCustomers } from './components/MerchantCustomers';

export const SchedulingModule = () => {
  const theme = useTheme();
  const { isCancelExperienceEventEnabled, isRecurringEventsEnabled } =
    useFeatureFlags();

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [newListing, setNewListing] = useState<CreateListing>({
    id: '',
    title: '',
    date: '',
    meetingPoint: undefined,
    description: '',
  });
  const [dateSet, setDateSet] = useState<{
    start: Date;
    end: Date;
    timeZone: string;
  }>(() => {
    const today = now();
    const start = startOfMonth(today);
    const end = endOfMonth(today);

    return {
      start: start,
      end: end,
      timeZone: 'local',
    };
  });

  const [selectedListing, setSelectedListing] = useState<Listing>();
  const [listingError, setListingError] = useState<null | string>(null);
  const [editingListing, setEditingListing] = useState(false);
  const [alertDialogOpen, setAlertDialogOpen] = useState(false);
  const [showSelectionDialogCancellation, setShowSelectionDialogCancellation] =
    useState(false);
  const [showSelectionDialogEdit, setShowSelectionDialogEdit] = useState(false);
  const [editRecurringSeries, setEditRecurringSeries] = useState(false);
  const [changesInputted, setChangesInputted] = useState(
    !isRecurringEventsEnabled || false
  );

  const [selectedProductIds, setSelectedProductIds] = useState<string[]>([]);
  const [eventsFilter, setEventsFilter] = useState<string>('all');

  const { data: experiences } = useExperiences();
  const schedulableExperiences: Experience[] =
    filterAndSortCalendarViewExperience(experiences || [], [
      ExperienceStatus.Approved,
      ExperienceStatus.Published,
    ]);

  const selectedExperience = useMemo(() => {
    if (selectedProductIds.length === 1) {
      return schedulableExperiences?.find(
        experience => experience.id === selectedProductIds[0]
      );
    }
    return undefined;
  }, [schedulableExperiences, selectedProductIds]);

  const { data: venue } = useLocations({
    locationId:
      selectedListing?.meetingPoint?.id?.toString() ||
      selectedExperience?.location?.id?.toString() ||
      '',
  });

  const {
    data: responseRecurringEventDto,
    refetch: refetchResponseRecurringEventDto,
  } = useRecurringEvents({
    recurringEventId: selectedListing?.recurringEventId,
  });

  const { data: merchantListings, refetch: refetchMerchantListings } =
    useMerchantListings({
      startsAt: dateSet?.start,
      endsAt: dateSet?.end,
    });

  const { data: platformFee } = useMerchantPlatformFee(
    ProductTypes.ONE_TIME_EVENT
  );

  useEffect(() => {
    if (venue && selectedListing) {
      setSelectedListing({
        ...selectedListing,
        meetingPoint: venue,
      });
    }
  }, [venue]);

  // format the listings once and filter them based on the selected product ids, bcs user get all merchant listings in first visit, so no necessary to refetch them
  const listings = useMemo(
    () => formatListing(merchantListings || [], experiences || [], theme),
    [merchantListings, experiences]
  );

  const filteredListings = useMemo(() => {
    const filteredByProduct = listings?.filter(listing =>
      selectedProductIds.includes(listing.productId)
    );

    if (eventsFilter === EVENTS_FILTERS.BOOKED) {
      return filteredByProduct?.filter(listing => listing.hasBookings);
    }

    return filteredByProduct;
  }, [listings, selectedProductIds, eventsFilter]);

  // calculates the end time of an event by adding the experience's duration to the start date
  const calculateEndTime = (startDate: Date) => {
    if (
      selectedExperience?.duration?.hours &&
      selectedExperience?.duration?.minutes
    ) {
      const endDate = new Date(startDate);
      const minutesToAdd =
        selectedExperience.duration.hours * 60 +
        selectedExperience.duration.minutes;
      endDate.setMinutes(endDate.getMinutes() + minutesToAdd);
      return endDate.getDay() !== startDate.getDay() ? undefined : endDate; // don't set end date if it will be next day
    }
    return undefined;
  };

  const handleCalendarClick = (arg: DateClickArg) => {
    if (
      schedulableExperiences.length <= 0 ||
      !selectedExperience ||
      selectedExperience?.bookingMethods?.includes(
        BookingMethod.REQUEST || BookingMethod.ADVERT
      )
    ) {
      return;
    }
    const today = new Date();
    setSelectedListing(undefined);
    let eventStart, eventEnd, eventDate; // if user is creating event on week/day view, split date into date and time
    if (arg.dateStr.includes('T')) {
      eventDate = arg.dateStr.split('T')[0];
      eventStart = arg.date;
      eventEnd = calculateEndTime(eventStart);
    }
    if (!drawerOpen && today <= arg.date) {
      setNewListing({
        id: selectedExperience?.id,
        title: selectedExperience?.title || '',
        date: eventDate || arg.dateStr,
        startsAt: eventStart || undefined,
        endsAt: eventEnd || undefined,
        price: selectedExperience?.price,
        totalAmount: selectedExperience?.totalAmount
          ? selectedExperience?.totalAmount
          : isNaN(
              parseFloat(
                calculatePriceWithPlatformFee(
                  selectedExperience.price,
                  platformFee || DEFAULT_PLATFORM_FEE,
                  true
                )
              )
            )
          ? 0
          : parseFloat(
              calculatePriceWithPlatformFee(
                selectedExperience.price,
                platformFee || DEFAULT_PLATFORM_FEE,
                true
              )
            ),
        meetingPoint: venue || undefined,
        description: selectedExperience?.location?.info || '',
      });
      setListingError(null);
      setDrawerOpen(!drawerOpen);
    }
  };

  const handleEventClick = async (id: string) => {
    const clickedListing = merchantListings?.find(listing => listing.id === id);
    if (!clickedListing) {
      return;
    }
    setSelectedListing(clickedListing);
    setDrawerOpen(!drawerOpen);
  };

  const closeWindow = () => {
    setDrawerOpen(false);
    setListingError(null);
    setEditingListing(false);
    setChangesInputted(false);
  };

  const onMutationSuccess = () => {
    setListingError(null);
    refetchMerchantListings();
    refetchResponseRecurringEventDto();
    closeWindow();
  };

  const onMutationError = (message: string) => {
    setListingError(message);
  };

  // updating the listing to being "close" which does not close the drawer
  const onMutationCloseListingSuccess = () => {
    setListingError(null);
    refetchMerchantListings();
    refetchResponseRecurringEventDto();
  };

  const {
    addListingMutation: { mutate: addListing, isLoading: isAddListingLoading },
    editListingMutation: {
      mutate: editListing,
      isLoading: isEditListingLoading,
    },
    cancelListingMutation: {
      mutate: cancelListing,
      isLoading: isCancelListingLoading,
    },
  } = useMutationListing(
    { selectedExperience, selectedListing },
    onMutationSuccess,
    onMutationError
  );

  const handleSubmit = async (data: AddListingInput) => {
    addListing(data);
  };

  const handleEditListingSubmit = async (data: EditListingInput) => {
    editListing(data);
  };

  const handleViewListingCancel = async (data: CancelListingInput) => {
    cancelListing(data);
  };

  const handleCloseAlertDialog = (selected: boolean) => {
    setAlertDialogOpen(false);
    if (selected) {
      handleViewListingCancelListing();
    } else {
      closeWindow();
    }
  };

  const handleViewListingCancelListing = async () => {
    try {
      handleViewListingCancel({
        listingId: selectedListing?.id || '',
        editRecurringSeries: false,
      });

      setListingError(null); // if a previous attempt resulted in error, remove error banner if this is successful
    } catch (err) {
      console.error(err);
      setListingError("We couldn't cancel your listing");
      return;
    }
    // Once the listing gets created, refetch listings hook
    refetchMerchantListings();
    closeWindow();
  };

  const handleViewListingSubmit = () => {
    // TODO: submit logic for saving a "Published" listing goes here
    console.log('submit view listing');
  };

  const handleSelectionDialogCancellation = (selected: string | undefined) => {
    setShowSelectionDialogCancellation(false);

    if (selected !== undefined) {
      handleViewListingCancel({
        recurringEventId: responseRecurringEventDto?.recurringEvent?.id,
        listingId: selectedListing?.id || '',
        editRecurringSeries: selected === EditRecurringEvent.isRecurringEvent,
      });
    }
    // if cancel is selected - do nothing
  };

  const handleSelectionDialogEdit = (selected: string | undefined) => {
    setShowSelectionDialogEdit(false);

    if (selected !== undefined) {
      setEditRecurringSeries(selected === EditRecurringEvent.isRecurringEvent);
      setEditingListing(true);
    }
    // if cancel is selected - do nothing
  };

  const toggleEdit = () => {
    if (responseRecurringEventDto?.recurringEvent) {
      // setEditRecurringSeries(false); // default value
      setShowSelectionDialogEdit(true);
    } else {
      setEditRecurringSeries(false);
      setEditingListing(true);
    }
  };

  // add event button
  const handleAddListing = () => {
    setSelectedListing(undefined);
    setNewListing({
      id: selectedExperience?.id,
      title: selectedExperience?.title || '',
      date: formatDateShortForm(tomorrow),
      totalAmount: selectedExperience?.totalAmount,
      meetingPoint: venue || undefined,
      description: selectedExperience?.location?.info || '',
    });
    setListingError(null);
    setDrawerOpen(true);
  };

  const calculateExperienceLength = () =>
    selectedExperience?.duration?.hours !== undefined &&
    selectedExperience?.duration?.minutes !== undefined
      ? selectedExperience.duration.hours * 60 +
        selectedExperience.duration.minutes
      : 0;

  // Determines which title and screen to show in the DetailsDrawer
  let title = '';
  let detailsDrawerType: 'Add' | 'Edit' | 'View';
  if (!editingListing) {
    detailsDrawerType = selectedListing ? 'View' : 'Add';
  } else {
    detailsDrawerType = 'Edit';
  }
  switch (detailsDrawerType) {
    case 'Add':
      title = 'Add Event';
      break;
    case 'Edit':
      title = 'Edit Event Details';
      break;
    case 'View':
    default:
      title = 'Event Details';
      break;
  }

  // Edit action for the view listing screen
  const actionTop =
    !!isListingModifiable(selectedListing) && detailsDrawerType === 'View' ? (
      <Button size="small" onClick={toggleEdit}>
        Edit
      </Button>
    ) : undefined;

  // Different actions depending on the screen user is on
  const actions =
    detailsDrawerType === 'Add' ? (
      <>
        <Button variant="text" onClick={closeWindow}>
          Cancel
        </Button>
        <Button
          type="submit"
          disabled={isAddListingLoading}
          form="addListingForm"
        >
          Save
        </Button>
      </>
    ) : detailsDrawerType === 'Edit' ? (
      changesInputted ? (
        <>
          <Button variant="text" onClick={closeWindow}>
            Discard
          </Button>
          <Button
            type="submit"
            disabled={isEditListingLoading || isCancelListingLoading}
            form="editListingForm"
          >
            Publish Changes
          </Button>
        </>
      ) : (
        <Button variant="text" onClick={closeWindow}>
          Cancel
        </Button>
      )
    ) : detailsDrawerType === 'View' ? (
      !isCancelExperienceEventEnabled &&
      isListingModifiable(selectedListing) ? (
        <Button
          disabled={isCancelListingLoading}
          variant="text"
          onClick={() => {
            if (
              isRecurringEventsEnabled &&
              !!responseRecurringEventDto?.recurringEvent
            ) {
              setShowSelectionDialogCancellation(true);
            } else {
              setAlertDialogOpen(true);
            }
          }}
          // It suppose to have "spacers.xl : 32px" but there are "spacers.m : 16px" of paddings
          // added in this file already: src /components/DetailsDrawer/DetailsDrawer.styles.ts (line 13)
          sx={{ color: 'error.main', marginY: theme.spacers.m }}
        >
          Cancel event
        </Button>
      ) : undefined
    ) : undefined;

  if (eventsFilter === EVENTS_FILTERS.CUSTOMERS) {
    return <MerchantCustomers onChangeSelectedFilter={setEventsFilter} />;
  }

  // send proper component to details drawer, depending on user intent(add, view, edit)
  return (
    <Container sx={{ paddingY: theme.spacers.xxl4 }}>
      {isRecurringEventsEnabled && showSelectionDialogEdit && (
        <SelectionDialog
          open={showSelectionDialogEdit}
          onClose={handleSelectionDialogEdit}
          image={<CalendarIcon />}
          title="Edit Reoccurring Event"
          selections={selectionDialogProp}
          agree="Confirm"
          disagree="Cancel"
        />
      )}
      {isRecurringEventsEnabled && showSelectionDialogCancellation && (
        <SelectionDialog
          open={showSelectionDialogCancellation}
          onClose={handleSelectionDialogCancellation}
          image={<CalendarIcon />}
          title="Cancel Reoccurring Event"
          selections={selectionDialogProp}
          agree="Confirm"
          disagree="Cancel"
        />
      )}
      {alertDialogOpen && (
        <AlertDialog
          open={alertDialogOpen}
          onClose={handleCloseAlertDialog}
          title="Cancel Event"
          message={
            selectedListing?.hasBookings
              ? 'You are about to cancel event for all participants. We will notify all participants under this event.'
              : 'Are you sure you want to cancel this event?'
          }
          // TODO: we don't have the number of users easily available q_q
          agree="Yes"
          disagree="No"
        />
      )}
      {schedulableExperiences.length ? (
        <Grid container spacing={4}>
          <Grid item sm={12} sx={{ pb: 8 }}>
            <Grid
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                mb: 4,
                alignItems: 'center',
              }}
            >
              <Typography variant="h4">Schedule</Typography>

              <Button
                sx={{
                  visibility:
                    !selectedExperience ||
                    selectedExperience?.bookingMethods?.includes(
                      BookingMethod.REQUEST
                    )
                      ? 'hidden'
                      : 'visible',
                }}
                onClick={handleAddListing}
              >
                Add Event
              </Button>
            </Grid>
            <StyledCalendar>
              <CalendarFilter
                onChangeSelectedProducts={setSelectedProductIds}
                products={schedulableExperiences.map(experience => ({
                  title: experience.title,
                  value: experience.id,
                }))}
                onChangeSelectedFilter={setEventsFilter}
                selectedFilter={eventsFilter}
                sx={{
                  left: 350,
                  position: 'absolute',
                  zIndex: theme.zIndex.mobileStepper,
                  // The left part (350px) is the space occupied by the calendar, and the right part (350px) is for the toggle buttons.
                  // This ensures that the dropdown is centered between these elements and doesn’t overlap with anything.
                  maxWidth: 'calc(100% - 700px)',
                  width: '100%',
                  height: 55,
                }}
              />
              <Calendar
                onDatesSet={setDateSet}
                events={filteredListings}
                height="auto"
                width="100%"
                handleNewEventClick={handleCalendarClick}
                handleEventClick={handleEventClick}
              />
            </StyledCalendar>
          </Grid>
          <DetailsDrawer
            actions={actions}
            actionTop={actionTop}
            closeWindow={closeWindow}
            drawerOpen={drawerOpen}
            title={title}
            // TODO: improve verification to see if a listing can be editing
            // ie: dates in the past and within 24hours of listing startDate
          >
            {detailsDrawerType === 'Add' ? (
              <AddListing
                handleSubmit={handleSubmit}
                listing={newListing}
                listingError={listingError}
                experienceType={selectedExperience?.type}
                experienceLength={calculateExperienceLength()}
              />
            ) : detailsDrawerType === 'Edit' && selectedListing ? (
              //  if listing is recurring send to edit otherwise send to old
              // will likely be changed but waiting to hear back from Product
              <EditListing
                handleSubmit={handleEditListingSubmit}
                listing={selectedListing}
                recurringEvent={responseRecurringEventDto?.recurringEvent}
                listingError={listingError}
                experienceLength={calculateExperienceLength()}
                editRecurringSeries={editRecurringSeries}
                experienceType={selectedExperience?.type}
                setChangesInputted={setChangesInputted}
              />
            ) : detailsDrawerType === 'View' && selectedListing ? (
              <ViewListing
                handleSubmit={handleViewListingSubmit}
                listing={selectedListing}
                venue={venue}
                recurringEvent={responseRecurringEventDto?.recurringEvent}
                listingError={listingError}
                experienceType={selectedExperience?.type}
                onMutationCloseListingSuccess={onMutationCloseListingSuccess}
              />
            ) : null}
          </DetailsDrawer>
        </Grid>
      ) : (
        <EmptyState
          content="To create a schedule of events you need to have a published product"
          icon="/images/calendar.svg"
          cta={{ label: 'Go to Products', link: '/experiences' }}
        />
      )}
    </Container>
  );
};

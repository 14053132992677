import { useNavigate } from 'react-router-dom';
import MiniCard from '@/components/MiniCard/MiniCard';
import MiniCardSkeleton from '@/components/MiniCard/MiniCardSkeleton';
import EmptyState from '@/components/BlockSections/EmptyState';
import { useExperiences, useMerchant } from '@/hooks';
import { ExperienceStatus } from '@/types';
import { SkeletonGrid } from './MerchantExperiencesModule.styles';
import { formatDate, formatTime } from '@/utils';
import {
  Button,
  Container,
  Grid,
  Typography,
  useTheme,
} from '@silverstein-properties/inspirelabs-ui';
import { MerchantType } from '@/types/Merchant';
import { merchantVisibilityConfig } from '@/config/merchantVisibilityConfig';
import { useState } from 'react';
import { SelectBookingTypeDialog } from '../CommonModule/SelectBookingTypeDialog';

export const MerchantExperiencesModule = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { data: experiences, isLoading } = useExperiences();
  const [openSelectBookingType, setOpenSelectBookingType] = useState(false);

  const { data: merchant } = useMerchant();
  const canCreateExperience =
    merchantVisibilityConfig[merchant?.type || MerchantType.STANDARD]
      .experiences.createNewExperience;

  const displayExperiences = () => {
    // Sort the Hub experiences by updatedAt or createdAt in descending order
    const sortedExperiences = experiences?.sort((a, b) => {
      const dateA = new Date(a.updatedAt ?? a.createdAt).getTime();
      const dateB = new Date(b.updatedAt ?? b.createdAt).getTime();
      return dateB - dateA; // descending order
    });

    return sortedExperiences?.map(experience => {
      const mDate = experience.updatedAt ?? experience.createdAt;
      const modifiedDate = mDate ? formatDate(mDate) : '';
      const modifiedTime = mDate ? formatTime(mDate) : '';

      const status =
        experience.status !== undefined
          ? ExperienceStatus[experience.status]
          : ExperienceStatus[1];

      return (
        <Grid key={experience.id} item xs={12}>
          <MiniCard
            title={experience.title || 'Untitled experience'}
            status={status}
            date={modifiedDate}
            time={modifiedTime}
            supportingText={experience.internalName || ''}
            image={experience.coverPhoto || undefined}
            onEdit={() =>
              navigate(`/experiences/${experience.id}`, {
                state: { from: document.location.pathname },
              })
            }
            onView={() =>
              navigate(`/experiences/${experience.id}`, {
                state: { from: document.location.pathname },
              })
            }
          />
        </Grid>
      );
    });
  };

  if (!isLoading && !experiences?.length) {
    return (
      <EmptyState
        text="You haven't added any products yet"
        mainBtnText="Create new product"
        onMainBtnClick={() => setOpenSelectBookingType(true)}
      />
    );
  }

  return (
    <>
      <SelectBookingTypeDialog
        open={openSelectBookingType}
        onCloseModal={() => {
          setOpenSelectBookingType(false);
        }}
      />
      <Container sx={{ paddingY: theme.spacers.xxl4 }}>
        <Grid container spacing={6}>
          <Grid
            container
            item
            xs={12}
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h4">Products</Typography>
            {!isLoading && canCreateExperience ? (
              <Button
                data-testid="newExperienceButton"
                size="large"
                onClick={() => setOpenSelectBookingType(true)}
              >
                New Product
              </Button>
            ) : null}
          </Grid>

          <Grid container item xs={12}>
            {isLoading ? (
              <SkeletonGrid container>
                <MiniCardSkeleton />
                <MiniCardSkeleton />
                <MiniCardSkeleton />
                <MiniCardSkeleton />
              </SkeletonGrid>
            ) : (
              <Grid container spacing={2}>
                <Grid container item xs={12} spacing={2} sx={{ marginLeft: 0 }}>
                  <Grid item xs sx={{ ml: 0, mr: 16 }}>
                    <Typography color="textSecondary" variant="body2">
                      Products
                    </Typography>
                  </Grid>
                  <Grid item xs display="flex" justifyContent="flex-end">
                    <Typography color="textSecondary" variant="body2">
                      Status
                    </Typography>
                  </Grid>
                  <Grid item xs display="flex" justifyContent="flex-end">
                    <Typography color="textSecondary" variant="body2">
                      Updated
                    </Typography>
                  </Grid>
                  <Grid item xs sx={{ px: 2 }} />
                </Grid>
                {displayExperiences()}
              </Grid>
            )}
          </Grid>
        </Grid>
      </Container>
    </>
  );
};
